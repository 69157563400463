import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ModMenuData, UserProgress, CustomError, ProgressData } from '../models';
import { MenuStatusTypes, ErrorMessages, OutsideUrls } from '../constants';
import { UserService } from './user.service';
import { Store } from '@ngrx/store';
import * as fromSidebar from '../sidebar/state';
import * as sidebarActions from '../sidebar/state/sidebar.actions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FlowService {

  links: string[] = [];
  flowMap$ = new BehaviorSubject<ModMenuData[]>(null);
  hasError$ = new Subject<boolean>();

  constructor(private http: HttpClient, private loc: Location, private userService: UserService, private router: Router, private store: Store<fromSidebar.State>) {
    // new code
    this.userService.progressData$.subscribe(dta => {
      const flow = this.flowMap$.getValue();
      if (flow) {
        this.userService.flow = flow;
        this.store.dispatch(new sidebarActions.Update(flow));
      }
    });
  }

  getNext(path?: string): string {
    const curPath = path || this.loc.path();
    let nextPath: string;
    this.links.some((link, index): any => {
      if (link === curPath) {
        console.log('link === curPath')
        nextPath = this.links[index + 1];
        return true;
      }
    });
    return nextPath;
  }

  getData(userType: string, progress: UserProgress): Observable<ModMenuData[]> {
    return this.http.get<ModMenuData[]>(OutsideUrls.FLOW.replace('$STATE', userType))
      .pipe(
        // tap(dta => console.log(dta)),
        map(dta => this.setStatus(dta, progress)),
        tap(dta => {
          this.hasError$.next(false);
          dta.forEach(mod => {
            mod.links.forEach(link => {
              this.links.push(link.url);
            });
          });
          this.links.push(OutsideUrls.COURSE_COMPLETE.replace('$STATE', userType));
        }),
        catchError(err => this.handleError(err))
      );
  }

  setStatus(arr: ModMenuData[], progress: UserProgress): ModMenuData[] {
    /* console.log('flowService.setStatus()', arr, progress); */
    const updatedFlow = JSON.parse(JSON.stringify(arr)).map((mod: ModMenuData, modIndex: number) => {
      if (modIndex === progress.curModIndex) {
        mod.status = MenuStatusTypes.IN_PROGRESS;
        mod.links.map((link, linkIndex) => {
          if (linkIndex === progress.linkIndexes) {
            link.status = MenuStatusTypes.IN_PROGRESS;
            this.router.navigateByUrl(link.url);
          } else if (linkIndex < progress.linkIndexes) {
            if (link.url.includes('/quiz/')) link.status = MenuStatusTypes.LOCKED;
            else link.status = MenuStatusTypes.COMPLETE;
          } else {
            link.status = MenuStatusTypes.NOT_STARTED;
          }
          return link;
        });
      } else if (progress.linkIndexes === mod.links.length) {
        mod.status = MenuStatusTypes.COMPLETE;
        mod.links.map(link => {
          if (link.url.includes('/quiz/')) link.status = MenuStatusTypes.LOCKED;
          else link.status = MenuStatusTypes.COMPLETE;
        });
      } else {
        if(mod.id < progress.curModIndex) {
          mod.status = MenuStatusTypes.COMPLETE;
          mod.links.map(link => {
            link.status = MenuStatusTypes.COMPLETE;
          })
        } else {
          mod.status = MenuStatusTypes.NOT_STARTED;
          mod.links.map(link => link.status = MenuStatusTypes.NOT_STARTED);
        }
      }
      return this.setModProgress(mod);
    });
    this.flowMap$.next(updatedFlow);
    console.log("this.userService.flow", this.userService.flow)
    return this.userService.flow = updatedFlow;
  }

  getCurrentPageStatus(): string {
    return this.getPageStatus(this.loc.path());
  }

  getPageStatus(url: string): string | null {
    if (!this.flowMap$.value) return null;
    //if (url.charAt(0) === '/') url = url.substr(1);
    url = decodeURIComponent(url);
    let status;
    this.flowMap$.getValue().find(mod => {
      const match = mod.links.find(link => link.url === url);
      if (match && match.status) status = match.status;
      return match ? true : false;
    });
    return status;
  }

  getCurrentPageTitle(): { mod: string, page: string } | undefined {
    return this.getPageTitle(this.loc.path());
  }

  getPageTitle(url: string): { mod: string, page: string } | undefined {
    const flow = this.flowMap$.getValue();
    if (!flow) return undefined;
    if (url.charAt(0) === '/') url = url.substr(1);
    url = decodeURIComponent(url);
    let title: { mod: string, page: string };
    flow.find(mod => {
      const match = mod.links.find(link => link.url === url);
      if (match && match.title) title = { mod: mod.title, page: match.title };
      return match ? true : false;
    });
    return title;
  }

  /* getCurrentModTitle(): string {
    let url = this.loc.path();
    if (url.charAt(0) === '/') url = url.substr(1);
    url = decodeURIComponent(url);
    let title: string;
    this.flowMap$.getValue().find(mod => {
      const match = mod.links.find(link => link.url === url);
      if (match) title = mod.title;
      return match ? true : false;
    });
    return title;
  } */

  private setModProgress(mod: ModMenuData): ModMenuData {
    let n = 0;
    mod.links.forEach(link => {
      if (link.status === MenuStatusTypes.COMPLETE || link.status === MenuStatusTypes.LOCKED) {
        n++;
      }
    });
    mod.progress = Math.round((n / mod.links.length) * 100);
    return mod;
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    const error: CustomError = {
      status: err.status,
      message: ErrorMessages.COURSE_FLOW
    };
    this.hasError$.next(true);
    return throwError(error);
  }
}
