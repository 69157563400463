import { Component, OnInit, ViewEncapsulation, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import * as fromShared from 'src/app/shared/state';
import { Store } from '@ngrx/store';
import { ClickRevealJsonData, ButtonsData } from './models';
import { gsap } from 'gsap/all';

@Component({
  selector: 'app-click-reveal',
  templateUrl: './click-reveal.component.html',
  styleUrls: ['./click-reveal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClickRevealComponent implements OnInit {

  private clicked: boolean[] = [];

  data!: ClickRevealJsonData;
  buttons!: ButtonsData[];
  nextLink!: string;
  complete!: boolean;

  constructor(
    private route: ActivatedRoute,
    private userServ: UserService,
    private store: Store<fromShared.State>,
  ) { }

  ngOnInit(): void {

    this.data = this.route.snapshot.data.json;
    this.buttons = this.data.buttons;
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.complete = this.route.snapshot.data.urlData.complete;
    this.buttons.forEach(() => {
      this.clicked.push(false);
    })
    this.instruct();

  }

  onClick(index: number): void {
    console.log(this.route.snapshot.data.json);
    this.store.dispatch(new superModalActions.HTML(this.data.buttons[index].text))
    this.store.dispatch(new superModalActions.Buttons([{
      func: () => {
        this.checkComplete()
      }
    }]))
    this.store.dispatch(new superModalActions.Show(0.2));
    this.clicked[index] = true;
  }

  private checkComplete(): void {
    if (!this.complete) {
      this.complete = this.clicked.map(b => b).reduce((prev, cur) => !prev ? prev : cur)
    }
  }

  onContinue(): void {
    this.userServ.activityComplete$.next(true);
  }

  private instruct(): void {
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.HTML(this.data.instruct));
    this.store.dispatch(new superModalActions.Show(0.6));
  }
}