import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from 'src/app/core/services/user.service';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';
import { gsap, SplitText } from 'gsap/all';

@Component({
  selector: 'app-click-slideshow',
  templateUrl: './click-slideshow.component.html',
  styleUrls: ['./click-slideshow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClickSlideshowComponent implements OnInit {

  nextLink!: string;
  complete = false;
  title = "";
  description = "";
  instruct = "";
  lists = [];
  activeCount = 0;
  allIds = 0;
  lastWords: string;
  image: string;

  constructor(
    private userServ: UserService,
    private store: Store<fromShared.State>,
    private route: ActivatedRoute,
    private element: ElementRef
  ) { }

  ngOnInit(): void {
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.complete = this.route.snapshot.data.urlData.complete;
    this.title = this.route.snapshot.data.json.title;
    this.instruct = this.route.snapshot.data.json.instruct;
    this.description = this.route.snapshot.data.json.description;
    this.lists = this.route.snapshot.data.json.lists;
    this.allIds = this.route.snapshot.data.json.allIds;
    this.lastWords = this.route.snapshot.data.json.lastWords;
    this.image = this.route.snapshot.data.json.image;
    this.store.dispatch(new loadAnimActions.Hide());
  }

  private animFadeIn(id: string): void {
    const selector = document.querySelector(`.slides-wrap [id="${id}"]`)
    const selectorPlaceHolder = document.querySelector(`.slides-wrap [id="${id}"] .placeholder`)
    console.log('selectorPlaceHolder',selectorPlaceHolder)
    console.log('selector',selector)
    if(this.activeCount === this.allIds) {
      this.complete = true;
    }
    gsap.timeline()
    .fromTo(selector, { xPercent: 0, autoAlpha: 0 }, { duration: 2, autoAlpha: 1, ease: 'power3.out' })

    gsap.timeline()
    .fromTo(selectorPlaceHolder, { xPercent: 0, autoAlpha: 1, display: 'inline-block'}, { duration: 0, autoAlpha: 0, display: 'none', ease: 'power3.out' })
  }

  onClick(event): void {
    console.log('event',event)
    if(this.activeCount < this.allIds) {
      this.activeCount += 1;
      this.animFadeIn(`${this.activeCount}`);
    }
  }

  onContinue(): void {
    this.userServ.activityComplete$.next(true);
  }
}
