<div class="module-menu" [attr.data-status]="data.status" [ngClass]="state">
    <div class="module-menu-header" (click)="onModMenuClick()">
      <div class="dot">{{ index }}</div>
      <div class="text">
        <span class="title">{{ data.title }}</span>
        <span class="progress">{{ data.progress }}</span>
      </div>
      <div class="twirl"></div>
    </div>
    <ul>
      <li class="module-menu-item" *ngFor="let link of data.links" [attr.data-status]="link.status">
        <div class="line">
          <div class="dot" [attr.data-status]="link.status"></div>
        </div>
        <a class="title"
          [routerLink]="link.url"
          [queryParams]="link.params || null"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="active">
            {{ link.title }}
        </a>
      </li>
    </ul>
  </div>
  