import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import { gsap, Draggable, MorphSVGPlugin, InertiaPlugin } from 'gsap/all';
import { DDBJsonData, DDBTitlesData, DDBStatementData } from './model';
import { BtnConfigData } from 'src/app/shared/models';

@Component({
  selector: 'app-drap-drop-boxes',
  templateUrl: './drap-drop-boxes.component.html',
  styleUrls: ['./drap-drop-boxes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrapDropBoxesComponent implements OnInit {

  @ViewChild('drag', { static: true }) private dragElRef: ElementRef;
  @ViewChildren('boxes') private boxes: QueryList<ElementRef>;

  complete!: boolean;
  nextLink!: string;
  private statements!: DDBStatementData[];
  private dragger!: Draggable;
  private lidAnims: { [key: string]: gsap.core.Timeline } = {};
  private summaryIndex = -1;

  data!: DDBJsonData;
  titles: DDBTitlesData[];
  curStatement!: DDBStatementData;
  dragging = false;
  hit!: boolean;
  correct!: boolean;

  constructor(
    private route: ActivatedRoute,
    private userServ: UserService,
    private store: Store<fromShared.State>
  ) {
    gsap.registerPlugin(Draggable, MorphSVGPlugin, InertiaPlugin);
  }

  ngOnInit(): void {
    this.data = this.route.snapshot.data.json;
    this.titles = this.data.boxTitles;
    this.statements = this.data.statements;
    console.log('this.data',this.data)
    console.log('this.statements',this.statements)
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.complete = this.route.snapshot.data.urlData.complete;
    this.instruct();
  }

  private nextStatement(): void {
    this.curStatement = this.statements.shift();
    if (this.curStatement) {
      this.correct = undefined;
      this.hit = false;
      gsap.set(this.dragElRef.nativeElement, { x: 0, y: 0 });
      gsap.fromTo(this.dragElRef.nativeElement,
        { autoAlpha: 0, yPercent: 0, scale: 0.8 },
        { duration: 0.4, delay: 0.3, autoAlpha: 1, yPercent: 0, scale: 1, ease: 'back.out',
          onComplete: () => this.setDrag()
        }
      );
    } else {
      this.end();
    }
  }

  private setDrag(): void {
    this.dragger = Draggable.create(this.dragElRef.nativeElement, {
      type: 'x,y',
      bounds: '#act-wrap',
      inertia: true,
      zIndexBoost: false,
      onDragStart: () => {
        this.dragging = true;
      },
      onDragEnd: () => this.onRelease(),
      onThrowComplete: () => this.onRelease()
    })[0];
  }

  private onRelease(): void {
    if (this.hit) return;
    this.boxes.forEach((boxRef, i) => {
      if (this.dragger.hitTest(boxRef.nativeElement, '15%')) {
        this.hit = true;
        this.dragging = false;
        this.correct = boxRef.nativeElement.dataset.type === this.curStatement.type;
        if (this.correct) this.onHit(boxRef.nativeElement, boxRef.nativeElement.dataset.type);
      }
    });
    if (this.correct) return;
    else this.onMiss();
  }

  private onHit(box: SVGPathElement, id: string): void {
    this.dragger.kill();
    const boxRect = box.getBoundingClientRect();
    const dragParentRect = (this.dragElRef.nativeElement as HTMLElement).parentElement.getBoundingClientRect();
    //
    gsap.timeline()
      .to(this.dragElRef.nativeElement, {
        duration: 0.2,
        y: (boxRect.top - dragParentRect.top) + ((boxRect.height / 2) - (dragParentRect.height / 2) - (dragParentRect.height / 4)),
        x: (boxRect.left - dragParentRect.left) + ((boxRect.width / 2) - (dragParentRect.width / 2)),
        transformOrigin: 'center center',
        scale: 0.6,
        ease: 'power2.out'
      })
      .to(this.dragElRef.nativeElement, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.1,
        ease: 'power3.in'
      }
    );
    //
    this.lidAnims[id].play(0);
  }

  private onMiss(): void {
    this.dragging = false;
    gsap.to(this.dragElRef.nativeElement, {
      duration: 0.3, x: 0, y: 0, ease: 'power3.in', overwrite: true,
      onComplete: () => {
        if (this.correct === false) {
          this.dragger.kill();
          setTimeout(() => {
            this.feedback();
          }, 300);
        }
      }
    });
  }

  private feedback(): void {
    this.hit = false;
    this.correct = undefined;
    this.store.dispatch(new superModalActions.Reset())
    this.store.dispatch(new superModalActions.HTML(this.curStatement.feedback));
    this.store.dispatch(new superModalActions.Buttons([{
      func: () => this.setDrag()
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

  private setLidAnims(): void {
    this.titles.forEach(title => {
      this.lidAnims[title.id] = gsap.timeline({ paused: true, onComplete: () => this.nextStatement() })
        .to(`.box[data-type="${title.id}"] .lid`, { duration: 0.4, morphSVG: `.box[data-type="${title.id}"] .lid-open`, ease: 'power3.out', repeat: 1, yoyo: true }, 0)
        .to(`.svg-box[data-type="${title.id}"] .shadow`, { duration: 0.4, morphSVG: `.svg-box[data-type="${title.id}"] .shadow-open`, ease: 'power3.out', repeat: 1, yoyo: true }, 0);
    });
  }

  private instruct(): void {
    const btns: BtnConfigData[] = [{
      text: 'Begin',
      icon: 'next',
      func: () => {
        this.setLidAnims();
        this.nextStatement();
      }
    }];
    if (this.complete) btns.push({
      text: 'Skip',
      icon: 'next',
      link: this.nextLink
    });
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.Title('Instructions'));
    this.store.dispatch(new superModalActions.HTML(this.data.instruct));
    this.store.dispatch(new superModalActions.Buttons(btns));
    this.store.dispatch(new superModalActions.Show(0.6));

  }

  private end(): void {
    this.store.dispatch(new superModalActions.Reset())
    this.store.dispatch(new superModalActions.HTML(this.data.end));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'Continue',
      icon: 'next',
      func: () => this.summary()
    }]));
    this.store.dispatch(new superModalActions.Show(1));
  }

  private summary(): void {
    this.complete = true;
    let last = false;
    // this.summaryIndex++;
    // if (this.summaryIndex === this.data.summary.length - 1) {
      this.userServ.activityComplete$.next(true);
      last = true;
    // }
    this.store.dispatch(new superModalActions.Reset())
   // this.store.dispatch(new superModalActions.HTML(this.data.summary[this.summaryIndex]));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'Continue',
      icon: 'next',
      link: last ? this.nextLink : undefined,
      func: !last ? () => this.summary() : undefined
    }]));
    //this.store.dispatch(new superModalActions.Show());
  }

}
