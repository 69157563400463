import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
// state
import { StoreModule } from '@ngrx/store';
import { reducers } from './state';
// pipes
import { TimecodePipe } from './pipes/timecode.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
// directives
import { DatasetDirective } from './directives/dataset.directive';
import { SwipeDirective } from './directives/swipe.directive';
import { ResizeDirective } from './directives/resize.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// componnents
import { LoadingAnimComponent } from './loading-anim/loading-anim.component';
import { SuperModalComponent } from './super-modal/super-modal.component';
import { VideoPlayerComponent } from './video-player/video-player/video-player.component';
import { VideoTimelineComponent } from './video-player/video-timeline/video-timeline.component';
import { VideoUiComponent } from './video-player/video-ui/video-ui.component';
import { NoteComponent } from './note/note.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { CorrectDotsComponent } from './correct-dots/correct-dots.component';
import { ButtonComponent } from './button/button.component';
import { SlideShowArrowComponent } from './slide-show-arrow/slide-show-arrow.component';
import { HelpComponent } from './help/help.component';
import { LoaderAnimComponent } from './loader-anim/loader-anim.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatCheckboxModule,
    DragDropModule,
    StoreModule.forFeature('sharedModule', reducers)
  ],
  declarations: [
    SafeHtmlPipe,
    TimecodePipe,
    DatasetDirective,
    SwipeDirective,
    ResizeDirective,
    LoadingAnimComponent,
    LoaderAnimComponent,
    SuperModalComponent,
    VideoPlayerComponent,
    VideoTimelineComponent,
    VideoUiComponent,
    NoteComponent,
    SimpleModalComponent,
    CorrectDotsComponent,
    ButtonComponent,
    SlideShowArrowComponent,
    HelpComponent
  ],
  exports: [
    CommonModule,
    MatExpansionModule,
    MatCheckboxModule,
    DragDropModule,
    SafeHtmlPipe,
    TimecodePipe,
    DatasetDirective,
    SwipeDirective,
    ResizeDirective,
    LoadingAnimComponent,
    SuperModalComponent,
    VideoPlayerComponent,
    VideoTimelineComponent,
    VideoUiComponent,
    NoteComponent,
    SimpleModalComponent,
    CorrectDotsComponent,
    ButtonComponent,
    SlideShowArrowComponent,
    HelpComponent
  ]
})
export class SharedModule { }
