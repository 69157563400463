import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomError } from '../models';
import * as loadAnimActions from '../../shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';
import { ErrorMessages, PageLabels, OutsideUrls, HeaderTitles } from '../constants';
import { UserService } from '../services/user.service';

export function handleCustomError(error: CustomError, router: Router, store: Store<any>, userServ: UserService): void {
  if (error) {
    // tslint:disable-next-line:triple-equals
    if (error.status == 200) error.status = HeaderTitles.MALFORMED;
    router.navigateByUrl(`/course/error/${error.status}`);
    store.dispatch(new loadAnimActions.Hide());
    setTimeout(() => {
      store.dispatch(new superModalActions.Reset());
      store.dispatch(new superModalActions.Title(`ERROR: ${error.status}`));
      store.dispatch(new superModalActions.HTML(`<p>${error.message}</p><p>${ErrorMessages.TRY_AGAIN}</p>`));
      store.dispatch(new superModalActions.Buttons([{
        text: PageLabels.LOG_OUT,
        func: () => {
          userServ.clearCookie();
          window.location.href = OutsideUrls.LOG_OUT;
        }
      }]));
      store.dispatch(new superModalActions.Show());
    }, 1000);
  }
}
