import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { UserService } from '../services/user.service';
import { OutsideUrls, PageLabels } from '../constants';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-user-menu',
  template: `
    <nav class="user-menu" [@userMenuState]="state" [attr.data-menu-state]="state">
      <ul>
        <li class="user-name">{{ userName }}</li>
        <li *ngFor="let link of links">
          <a (click)="onClick(link.url)">{{ link.title }}</a>
        </li>
      </ul>
    </nav>`,
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('userMenuState', [
      state('hide', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      state('show', style({
        opacity: 1,
        visibility: 'visible'
      })),
      transition('hide => show', animate('50ms linear')),
      transition('show => hide', animate('150ms 50ms linear')),
    ])
  ]
})
export class UserMenuComponent implements OnInit {

  state = 'hide';
  userName: string;
  links = [
    {
      title: PageLabels.PROFILE,
      url: 'profile'
    },
    {
      title: PageLabels.LOG_OUT,
      url: OutsideUrls.LOG_OUT
    }
  ];

  constructor(
      private service: UserService,
      private router: Router,
      private storage: StorageService,
      private idle: Idle
    ) { }

    @HostListener("click")
    clicked() {
      this.service.showMenu$.next(false);
    }
    
  ngOnInit(): void {
    this.service.username$.subscribe(name => this.userName = name);
    this.service.showMenu$.subscribe(boo => this.state = boo ? 'show' : 'hide');
  }

  onClick(url: string): void {
    console.log(url);
    if (url === OutsideUrls.LOG_OUT) {
      this.service.clearCookie();
      sessionStorage.removeItem("token");
      this.service.loggedIn$.next(false);
      this.service.showMenu$.next(false);
      this.router.navigateByUrl(`/`);
      this.idle.stop();
      console.log('storage',this.storage)
    } else if (url === "profile") {
      this.router.navigateByUrl(`course/a/user/profile`);
    } else {
      console.log('url')
    }
    url = url.replace('$STATE', this.service.userType);
    this.service.clickStream$.next({ enter: false, url: window.location.pathname, redirect: url });
  }

}
