import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-correct-dots',
  template: `
    <div class="dots-wrap" [ngStyle]="{'width.%': fills && fills.length < 10 ? 10 * fills.length : 100}">
      <div *ngFor="let fill of fills; index as i" attr.data-fill="{{ fill }}" attr.data-current="{{ i === current }}" class="dot" (click)="onDotClick(i)"></div>
    </div>
  `,
  styleUrls: ['./correct-dots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CorrectDotsComponent {

  @Input() fills: number[];
  @Input() current: number;
  @Output() currentChange = new EventEmitter<number>();
  @Input() allowClick: boolean;

  constructor() { }

  onDotClick(index: number) {
    if (this.allowClick === !false) this.currentChange.emit(index);
  }

}
