import { Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import { Subscription } from 'rxjs';
import { ModMenuData, ModMenuStatus, ProgressData, UserProgress } from 'src/app/core/models';
import { MenuStatusTypes } from 'src/app/core/constants';
import { gsap } from 'gsap';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-modex',
  templateUrl: './modex.component.html',
  styleUrls: ['./modex.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModexComponent implements OnInit, OnDestroy {

  @ViewChildren('btns') private buttons: QueryList<ElementRef>;
  private progress: UserProgress;
  private sub: Subscription;
  private tl: gsap.core.Timeline;

  flow: ModMenuData[];
  inProgress: boolean;
  nextModule: boolean;
  clicked = false;

  constructor(
    private router: Router,
    private userServ: UserService,
    private store: Store<fromShared.State>,
    private route: ActivatedRoute,
    private idle: Idle
  ) { }

  ngOnInit(): void {
    this.store.dispatch(new loadAnimActions.Hide());
    this.sub = this.userServ.progress$.subscribe(prog => {
      if (prog) {
        console.log("prog",prog)
        let newUserFlow
        this.progress = JSON.parse(JSON.stringify(prog));
        if(sessionStorage.role == 1) {
          newUserFlow = this.userServ.flow.filter(data => data.id !== 7)
          console.log("this.userServ.flow1",this.userServ.flow)
        } else {
          newUserFlow = this.userServ.flow;
          console.log("this.userServ.flow2",this.userServ.flow)
        }
        console.log("newUserFlow", newUserFlow)
        this.flow = JSON.parse(JSON.stringify(newUserFlow));
        const length = this.flow[this.progress.curModIndex].links.length;
        if ((this.progress.linkIndexes === length - 1) || (this.progress.linkIndexes === length)) {
          this.flow[this.progress.curModIndex].status = MenuStatusTypes.COMPLETE;
          // Update until it's the last module
          if(this.progress.curModIndex + 1 < this.flow.length) {
            this.progress.curModIndex += 1;
            this.flow[this.progress.curModIndex].status = MenuStatusTypes.NEXT_MODULE;
          } else {
            // show the logout modal if the last module is completed
            this.store.dispatch(new superModalActions.HTML(`<h2>Congrats! You have completed all the modules! Please log out.</h2>`))
            this.store.dispatch(new superModalActions.Buttons([ 
                {
                  text: 'Logout',
                  icon: 'next',
                  func: () => this.logout()
                },
                {
                  text: 'Cancel',
                  icon: 'close',
                }
              ])
            )
            this.store.dispatch(new superModalActions.Show());

          }
        }
        this.inProgress = this.flow.find(mod => mod.status === MenuStatusTypes.IN_PROGRESS) ? true : false;
        this.nextModule = this.flow.find(mod => mod.status === MenuStatusTypes.NEXT_MODULE) ? true : false;
      }
      console.log("this.inProgress", this.inProgress)
      console.log("this.nextModule", this.nextModule)
      setTimeout(() => {
        if(this.inProgress) {
          this.animate(MenuStatusTypes.IN_PROGRESS)
        } else if (this.nextModule) {
          this.animate(MenuStatusTypes.NEXT_MODULE)
        }
      }, 1000);
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    // this.tl.kill();
  }

  onClick(index: number): void {
    this.clicked = true;
    console.log(this.progress)
    this.progress.linkIndexes = 0; // reset the linkIndexes to start from 0
    if (this.progress.linkIndexes === this.flow[this.progress.curModIndex].links.length - 1) {
      this.progress.linkIndexes++;
    }
    this.progress.curModIndex = index;
    this.userServ.progress$.next(this.progress);
    console.log('this.flow[index].links[0].url', this.flow[index].links[0].url)
    this.router.navigateByUrl(this.flow[index].links[0].url)
    // this.userServ.updateProgress(this.progress).subscribe(
    //   () => this.router.navigateByUrl(this.flow[index].links[0].url)
    // );
  }

  private animate(status: ModMenuStatus): void {
    this.tl = gsap.timeline({repeat: -1, paused: true });
    const arr = this.buttons.toArray().map((ref: ElementRef) => ref.nativeElement).filter((el: HTMLElement) => el.dataset.status === status);
    arr.forEach((el: HTMLElement) => {
      const tl = gsap.timeline({ease: 'circ.out'})
        .to(el, { duration: 0.3, rotationY: 90 }, 4)
        .to(el.firstElementChild, { duration: 0.3, opacity: 0 }, 4)
        .call(() => el.classList.add('back'))
        .to(el, { duration: 0.6, rotationY: 270 })
        .call(() => el.classList.remove('back'))
        .to(el.firstElementChild, { duration: 0.3, opacity: 1})
        .to(el, { duration: 0.3, rotationY: 360}, '-=0.3');
      this.tl.add(tl, '-=4.8');
    });
    setTimeout(() => {
      this.tl.play();
    }, 1000);
    
  }

  logout() {
    this.userServ.clearCookie();
    sessionStorage.removeItem("token");
    this.userServ.loggedIn$.next(false);
    this.userServ.showMenu$.next(false);
    this.router.navigateByUrl(`/`);
    this.idle.stop();
  }

}
