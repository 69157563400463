import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CustomError } from "../models";
import { StorageService } from "./storage.service";

@Injectable({
providedIn: 'root'
})
export class BaseService {
    public apiEndpoint = environment.baseEndpoint || window.location.origin;

    /** 
     * Notify course of error (course only). 
     * Subbed in {@link CourseComponent}, next() in this service's [handleError]{@link UserService#handleError}.
     */
    courseError$ = new Subject<CustomError>();
    
    constructor(private httpClient: HttpClient,
                private storage: StorageService,) {
    }

    get headers(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.storage.get('token')}`,
        });
    }

    get options() {
        return { headers: this.headers };
    }

    get optionsResponseText() {
        return { headers: this.headers, responseType: 'text' };
    }

    get formDataHeaders(): HttpHeaders {
        return new HttpHeaders({
            Accept: 'application/json',
            Authorization: `Bearer ${this.storage.get('token')}`,
        });
    }

    // endregion

    // region Internal API
    get<T>(url: string): Observable<T> {
        return this.httpClient
            .get<T>(`${this.apiEndpoint}/${url}`, this.options);
    }

    getResponseText(url: string): Observable<string> {
        return this.httpClient
            .get(`${this.apiEndpoint}/${url}`, { headers: this.headers, responseType: 'text' });
    }

    getProgressData<T>(url: string): Observable<T> {
        return this.httpClient
            .get<T>(`${this.apiEndpoint}/${url}`, this.options);
    }

    post<T>(url: string, data: any): Observable<T> {
        console.log('url', url)
        console.log('data', data)
        console.log('this.apiEndpoint', this.apiEndpoint)
        console.log('this.options', this.options)
       
        if (data instanceof FormData) {
            const httpOptions = {
                headers: this.formDataHeaders
            };
            return this.httpClient.post<T>(`${this.apiEndpoint}/${url}`, data, httpOptions);
        } else {
            console.log(this.httpClient.post<T>(`${this.apiEndpoint}/${url}`, data))
            const httpOptions = {
                headers: this.headers
            };
            return this.httpClient
                .post<T>(`${this.apiEndpoint}/${url}`, data, httpOptions);
        }
    }

    postResponseText(url: string, data: any): Observable<string> {
        if (data instanceof FormData) {
            return this.httpClient.post(`${this.apiEndpoint}/${url}`, data, {
                headers: this.formDataHeaders,
                responseType: 'text'
            });
        } else {
            return this.httpClient
                .post(`${this.apiEndpoint}/${url}`, data, {
                    headers: this.headers,
                    responseType: 'text'
                });
        }
    }

    put<T>(url: string, data: any): Observable<T> {
        if (data instanceof FormData) {
            const httpOptions = {
                headers: this.formDataHeaders
            };
            return this.httpClient.put<T>(`${this.apiEndpoint}/${url}`, data, httpOptions);
        } else {
            return this.httpClient
                .put<T>(`${this.apiEndpoint}/${url}`, data, this.options);
        }
    }

    delete<T>(url: string): Observable<T> {
        const params: URLSearchParams = new URLSearchParams();
        const options = { headers: this.headers };

        if (params) {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const val = params[key];
                    params.set(key, val);
                }
            }

            (options as any).search = params;
        }

        return this.httpClient
            .delete<T>(`${this.apiEndpoint}/${url}`, options);
    }

    /**
   * When errors are caught they are passed to this method, which converts it to a CustomError and is thrown in the return.
   * The courseError$ Subject bradcasts the error for course related errors.
   * See {@link CourseComponent} and {@link handleCustomError} for course error handling.
   * @param err origonal HttpErrorResponse
   * @param msg message displayed to user
   * @param broadcast only for course related errors
   * @returns 
   */
    handleError(err: HttpErrorResponse, msg: string, broadcast?: boolean): Observable<never> {
        const error: CustomError = {
        status: err.status,
        message: msg
        };
        //
        if (broadcast) this.courseError$.next(error);
        return throwError(error);
    }
}