/*
  App wide components (SideBar, LoadingAnim) & singleton services shared across multiple modules go here in CoreModule
  reused components & directives go in SharedModule
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuTabComponent } from './sidebar/menu-tab/menu-tab.component';
import { ModMenuComponent } from './sidebar/mod-menu/mod-menu.component';
import { reducer as SidebarReducer } from './sidebar/state/sidebar.reducer';
import { SidebarEffects } from './sidebar/state/sidebar.effects';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { BlankComponent } from './blank/blank.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('sidebar', SidebarReducer),
    EffectsModule.forFeature([SidebarEffects])
  ],
  exports: [
    SidebarComponent,
    MenuTabComponent,
    ModMenuComponent,
    UserMenuComponent,
    BlankComponent
  ],
  declarations: [
    SidebarComponent,
    MenuTabComponent,
    ModMenuComponent,
    UserMenuComponent,
    BlankComponent
  ]
})
export class CoreModule { }
