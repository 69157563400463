import { Action } from '@ngrx/store';
import { BtnConfigData, InsertElementData } from '../../models';

export enum SuperModalActionTypes {
    Show = '[SuperModal] Show',
    Hide = '[SuperModal] Hide',
    Text = '[SuperModal] Set Text',
    Title = '[SuperModal] Set Title',
    HTML = '[SuperModal] Set HTML',
    Buttons = '[SuperModal] Set Buttons',
    Reset = '[SuperModal] Reset',
    Insert = '[SuperModal] Insert Element',
    FirstLoad = '[SuperModal] First Load',
}

export class Show implements Action {
    readonly type = SuperModalActionTypes.Show;
    constructor(public payload?: number) { }
}

export class Hide implements Action {
    readonly type = SuperModalActionTypes.Hide;
    constructor(public payload?: number) { }
}

export class Text implements Action {
    readonly type = SuperModalActionTypes.Text;
    constructor(public payload: string) { }
}

export class Title implements Action {
    readonly type = SuperModalActionTypes.Title;
    constructor(public payload: string) { }
}

export class HTML implements Action {
    readonly type = SuperModalActionTypes.HTML;
    constructor(public payload: string) { }
}

export class Buttons implements Action {
    readonly type = SuperModalActionTypes.Buttons;
    constructor(public payload: BtnConfigData[]) { }
}

export class Reset implements Action {
    readonly type = SuperModalActionTypes.Reset;
}

export class Insert implements Action {
    readonly type = SuperModalActionTypes.Insert;
    constructor(public payload: InsertElementData) { }
}

export class FirstLoad implements Action {
    readonly type = SuperModalActionTypes.FirstLoad;
    constructor(public payload: boolean) { }
}

export type SuperModalActions = Show
    | Hide
    | Text
    | Title
    | HTML
    | Buttons
    | Reset
    | Insert
    | FirstLoad;
