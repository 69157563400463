<div class="quiz-wrap">

  <section [innerHTML]="instructMsg | safeHtml"></section>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

    <ol formArrayName="quiz">

      <ng-container *ngFor="let item of questions, let i = index">

        <p class="feedback" *ngIf="id !== 'pre' && item.correctCode === 0">{{item.incorrectFeedback}}</p>
        <p class="feedback" *ngIf="id !== 'pre' && item.correctCode === 1">{{item.correctFeedback}}</p>

        <li [formGroupName]="i" [attr.data-correct]="item.correctCode">
          <span *ngIf="item.correctCode !== -1" class="correct-icon">
            <svg>
              <use [attr.xlink:href]="'./assets/svg_store/store.svg#' + (item.correctCode === 1 ? 'check' : 'close')"></use>
            </svg>
          </span>

          <span [innerHTML]="item.question | safeHtml"></span>
          <label class="choice" *ngFor="let choice of item.choices, let j = index">
            <!-- Single Option -->
            <ng-container *ngIf="item.type === 'radio'">
              <input type="radio" formControlName="answer" value="{{ j }}" (change)="onRadioChange($event, i, j)">

              <span class="checkmark">
                <svg>
                  <use xlink:href="./assets/svg_store/store.svg#check"></use>
                </svg>
              </span>
              {{ choice.answer }}
            </ng-container>
            <!-- Multiple Options -->
            <ng-container *ngIf="item.type === 'checkbox'">
              <input type="checkbox" formControlName="answer" value="{{ j }}" (change)="onCheckChange($event, i, j)">

              <span class="checkmark">
                <svg>
                  <use xlink:href="./assets/svg_store/store.svg#check"></use>
                </svg>
              </span>
              {{ choice.answer }}
            </ng-container>
             <!-- Matching -->
            <ng-container *ngIf="item.type === 'matching'">
              <div class="choice-select">
              <select type="select" formControlName="answer" (change)="onSelectChange($event.target.value, i, j)">
              <ng-container *ngFor="let option of choice.options">
                  <option [value]="option">{{ option }}</option>
              </ng-container>
            </select>
            {{ choice.answer }}
            </div>
            </ng-container>
          
            
          </label>
        </li>
      </ng-container>
    </ol>

    <div class="btn-wrap">
      <app-button type="submit" text="Submit" icon="check" alt="true" [disabled]="!form.valid"></app-button>
      <app-button type="button" text="Continue" icon="next" alt="true" [disabled]="continueBtn" (click)="onConinue()"></app-button>
    </div>

  </form>
</div>
