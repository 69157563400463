<div class="video-ui-wrap">
  <div class="timecode">{{ currentTime | timecode }} - {{ duration | timecode }}</div>
  <div class="btn-wrap">
    <div class="rewind-btn" (click)="onRewindBtn()">
      <svg>
        <use [attr.xlink:href]="'./assets/svg_store/store.svg#backTen'"></use>
      </svg>
    </div>
    <button class="play-toggle-btn" (click)="onPlayBtn()" [ngClass]="{paused : isPaused}"><span></span></button>
    <button class="app-btn cc-toggle-btn" (click)="onCCBtn()" [ngClass]="{on : showSubtitles}">CC</button>
  </div>
  <app-button *ngIf="next" text="Continue" class="green" icon="next" alt="true" [disabled]="!complete" [link]="next"></app-button>
</div>