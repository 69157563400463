<div class="ddb-wrap">

  <div class="text-wrap">
    <app-static [noButton]="true"></app-static>
  </div>

  <div id="act-wrap">

    <div class="boxes-wrap">

      <div class="box-wrap">
        <svg class="svg-box" [attr.data-type]="titles[0].id" x="0" y="0" viewBox="0 0 320 320" xml:space="preserve">
          <path class="bg"
            d="M291.2 0h-261C13.8 0 .4 13.4.4 29.8v260.9c0 16.4 13.4 29.8 29.8 29.8h260.9c16.4 0 29.8-13.3 29.8-29.8V29.8C321 13.4 307.6 0 291.2 0z" />
          
          <text x="50%" y="45" text-anchor="middle">{{titles[0].text}}</text>
          
          <path class="shadow"
            d="M235.8 97.7L98.1 125.2l-10.3 6 17.5 17.5-9.8 71.6 100.2 100.1h95.4c16.4 0 29.8-13.3 29.8-29.8V182.8l-85.1-85.1z" />
          <path class="shadow-open"
            d="M236.6 39.2L90.7 72.3l40.5 62.5-25.8 13.9-9.8 71.6 100.2 100.1h95.4c16.4 0 29.8-13.3 29.8-29.8V182.8L236.6 39.2z" />
          <g #boxes class="box" [attr.data-type]="titles[0].id">
            <path class="box-body"
              d="M95.9 135.9v84.8h132.2v-84.8H95.9zm82.9 23.7h-33.7c-1.9-.1-3.4-1.8-3.2-3.8.1-1.7 1.5-3.1 3.2-3.2h33.7c1.9.1 3.4 1.8 3.2 3.8-.1 1.7-1.5 3.1-3.2 3.2z" />
            <path class="lid" d="M86.9 97.4h150.2v34.4H86.9z" />
            <path class="lid-open" d="M86.9 36.9h150.2v34.4H86.9z" />
          </g>
        </svg>
      </div>

      <div class="box-wrap">
        <svg class="svg-box" [attr.data-type]="titles[1].id" x=" 0" y="0" viewBox="0 0 320 320" xml:space="preserve">
          <path class="bg"
            d="M291.2 0h-261C13.8 0 .4 13.4.4 29.8v260.9c0 16.4 13.4 29.8 29.8 29.8h260.9c16.4 0 29.8-13.3 29.8-29.8V29.8C321 13.4 307.6 0 291.2 0z" />
          
          <text x="50%" y="45" text-anchor="middle">{{titles[1].text}}</text>
          
          <path class="shadow"
            d="M235.8 97.7L98.1 125.2l-10.3 6 17.5 17.5-9.8 71.6 100.2 100.1h95.4c16.4 0 29.8-13.3 29.8-29.8V182.8l-85.1-85.1z" />
          <path class="shadow-open"
            d="M236.6 39.2L90.7 72.3l40.5 62.5-25.8 13.9-9.8 71.6 100.2 100.1h95.4c16.4 0 29.8-13.3 29.8-29.8V182.8L236.6 39.2z" />
          <g #boxes class="box" [attr.data-type]="titles[1].id">
            <path class="box-body"
              d="M95.9 135.9v84.8h132.2v-84.8H95.9zm82.9 23.7h-33.7c-1.9-.1-3.4-1.8-3.2-3.8.1-1.7 1.5-3.1 3.2-3.2h33.7c1.9.1 3.4 1.8 3.2 3.8-.1 1.7-1.5 3.1-3.2 3.2z" />
            <path class="lid" d="M86.9 97.4h150.2v34.4H86.9z" />
            <path class="lid-open" d="M86.9 36.9h150.2v34.4H86.9z" />
          </g>
        </svg>
      </div>

    </div>
    <app-button *ngIf= "complete" text = "Continue" icon = "next" id="continueButton" alt = "true" [link] = "nextLink" (click)="onContinue()"></app-button>
    <div class="text-holder">
      <div #drag class="statement" [ngClass]="{'dragging': dragging, 'correct': hit && correct, 'incorrect': hit && !correct}">
        <span class="draggable-item" *ngIf="curStatement" [innerHTML]="curStatement.text"></span>
      </div>
    </div>
  </div>
  <div class="some-space">
    &nbsp;
  </div>
</div>
