import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-video-ui',
  templateUrl: './video-ui.component.html',
  styleUrls: ['./video-ui.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoUiComponent {

  @Input() currentTime: number;
  @Input() duration: number;
  @Input() complete: boolean;
  @Input() next: string;
  @Input() isPaused: boolean;
  @Input() showSubtitles: boolean;

  @Output() playToggle = new EventEmitter();
  @Output() subtitlesToggle = new EventEmitter();
  @Output() seek = new EventEmitter<number>();

  constructor() { }

  onPlayBtn(): void {
    this.playToggle.emit();
  }

  onRewindBtn(): void {
    this.seek.emit(this.currentTime - 10);
  }

  onCCBtn(): void {
    this.subtitlesToggle.emit();
  }

}
