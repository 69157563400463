import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from 'src/app/core/services/user.service';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';
import { gsap, SplitText } from 'gsap/all';
import { SlideRevealColumn, SlideRevealJsonData } from './models';

@Component({
  selector: 'app-slide-reveal',
  templateUrl: './slide-reveal.component.html',
  styleUrls: ['./slide-reveal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlideRevealComponent implements OnInit {

  private clicked: boolean[] = [];
  private animComplete: boolean[] = [];

  data!: SlideRevealJsonData;
  columns!: SlideRevealColumn[];
  complete!: boolean;
  nextLink!: string;
  popupText!: string;

  @HostListener('window:resize', ['$event'])
  onResize(e: Event): void {
    if (this.el.nativeElement.firstChild.clientHeight >= window.innerHeight - 40) {
      this.el.nativeElement.style.display = 'block';
    } else {
      this.el.nativeElement.style.display = null;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private userServ: UserService,
    private store: Store<fromShared.State>,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.complete = this.route.snapshot.data.urlData.complete;
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.data = this.route.snapshot.data.json;
    this.columns = this.data.columns;
    this.popupText = this.data.popupText;
    this.columns[0].rows.forEach(() => {
      this.clicked.push(false);
      this.animComplete.push(false);
    });
    if(this.data.instruct) {
      this.instruct();
    }
  }

  onClick(rowIndex: number): void {
    if (this.clicked[rowIndex]) return;
    this.clicked[rowIndex] = true;
    if (this.columns.length === 2) {
      this.animFadeIn(`.box-item.column-1.row-${rowIndex}`, () => this.checkComplete(rowIndex));
    } else {
      this.animFadeIn(`.box-item.column-1.row-${rowIndex}`, () => this.animFadeIn(`.box-item.column-2.row-${rowIndex}`, () => this.checkComplete(rowIndex)));
    }
  }

  private animFadeIn(selector: string, onComplete?: () => void ): void {
    const split = new SplitText(selector + ' span', {type: 'words,chars'});
    gsap.timeline({ onComplete })
    .fromTo(selector, { xPercent: 0 }, { duration: 0.5, autoAlpha: 1, ease: 'power3.out' })
    .from(split.chars, { opacity: 0, ease: 'back' }, 0.3);
  }

  // private animFromBelow(selector: string, onComplete?: () => void ): void {
  //   const split = new SplitText(selector + ' span', {type: 'words,chars'});
  //   gsap.timeline({ onComplete })
  //     .fromTo(selector, { yPercent: 100 }, { duration: 0.5, autoAlpha: 1, yPercent: 0, ease: 'power3.out' })
  //     .from(split.chars, { duration: 0.3, opacity: 0, scale: 0, yPercent: 100, rotationX: 180, transformOrigin: '0% 50% -50', ease: 'back', stagger: 0.01 }, 0.2);
  // }

  // private animFromRight(selector: string, onComplete?: () => void ): void {
  //   const split = new SplitText(selector + ' span', { type: 'words,chars' });
  //   gsap.timeline({ onComplete })
  //     .fromTo(selector, { xPercent: 100 }, { duration: 0.5, autoAlpha: 1, xPercent: 0, ease: 'power3.out'})
  //     .from(split.chars, { duration: 0.3, opacity: 0, scale: 0, yPercent: 100, rotationX: 180, transformOrigin: '0% 50% -50', ease: 'back', stagger: 0.01 }, 0.2);
  // }

  private checkComplete(index: number): void {
    console.log("this.animComplete",this.animComplete)
    this.animComplete[index] = true;
    console.log("this.complete",this.complete)
    if (!this.complete) {
      console.log("this.clicked",this.clicked)
      this.complete = this.clicked.reduce((prev, cur) => !prev ? prev : cur) && this.animComplete.reduce((prev, cur) => !prev ? prev : cur);
      console.log("this.complete2",this.complete)
      if(this.complete && this.popupText) {
        this.store.dispatch(new superModalActions.Title(''))
        this.store.dispatch(new superModalActions.HTML(this.data.popupText))
        this.store.dispatch(new superModalActions.Buttons([{
          text: 'OK',
          icon: 'close',
        }]));
        this.store.dispatch(new superModalActions.Show(0.6))
      }
    }
  }

  onContinue(): void {
    this.userServ.activityComplete$.next(true);
  }

  private instruct(): void {
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.Title('Instructions'))
    this.store.dispatch(new superModalActions.HTML(this.data.instruct))
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'Begin',
      icon: 'next'
    }]));
    this.store.dispatch(new superModalActions.Show(0.6))
  }

}
