import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChild, ElementRef, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { HelpService } from 'src/app/shared/help/help-service/help.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('video', { static: true }) vidRef: ElementRef;

  @Input() source: string;
  @Input() stateSource: string;
  @Input() next: string;
  @Input() showPlayBtn = true;
  @Input() loadVtt = true;
  @Input() autoplay: boolean;

  @Output() loaded = new EventEmitter<number>();
  @Output() played = new EventEmitter<number>();
  @Output() currentTime = new EventEmitter<number>();
  @Output() duration = new EventEmitter<number>();
  // tslint:disable-next-line: no-output-native
  @Output() complete = new EventEmitter<boolean>();
  @Output() paused = new EventEmitter<boolean>();
  // tslint:disable-next-line: no-output-native
  @Output() waiting = new EventEmitter<boolean>();
  // tslint:disable-next-line: no-output-native
  @Output() playing = new EventEmitter<boolean>();
  @Output() subtitles = new EventEmitter<boolean>();

  playBtnClass = 'show';
  isPaused = false;
  vidEl: HTMLVideoElement;
  vttSrc: string;

  private progressUpdate: number;
  private buffer: number;
  private percentLoaded: any;
  private i: number;
  private l: number;
  private sub: Subscription;
  private showSubtitles: boolean = parseInt(sessionStorage.subtitles, null) ? true : false;
  private track: TextTrack;
  private vttDir = './assets/vtt/';

  constructor(private helpServ: HelpService) { }

  ngOnInit(): void {
    sessionStorage.subtitles = this.showSubtitles ? 1 : 0;
    this.subtitles.emit(this.showSubtitles);
    this.sub = this.helpServ.showing.subscribe(
      showing => {
        if (showing && !this.isPaused) {
          this.playToggle();
        }
      }
    );
  }

  ngOnChanges(): void {
    if (!this.vidEl) this.vidEl = this.vidRef.nativeElement as HTMLVideoElement;
    this.vidEl.load();
  }

  ngOnDestroy(): void {
    clearInterval(this.progressUpdate);
    this.sub.unsubscribe();
  }

  onDataLoaded(): void {
    const split = this.vidEl.currentSrc.split('/');
    const id = split[split.length - 1].split('.')[0];
    if (this.loadVtt.toString() === 'true') this.vttSrc = this.vttDir + id + '.vtt';
  }

  playToggle(): void {
    if (this.vidEl.paused || this.vidEl.ended) {
      this.vidEl.play();
      this.isPaused = false;
      this.paused.emit(false);
      this.waiting.emit(true);
    } else {
      this.vidEl.pause();
      this.isPaused = true;
      this.paused.emit(true);
      this.waiting.emit(false);
    }
    //
    this.playBtnClass = 'show';
    setTimeout(() => {
      this.playBtnClass = 'hide';
    }, 300);
  }

  seek(time: number): void {
    console.log(time)
    this.vidEl.currentTime = time;
  }

  subtitlesToggle(): void {
    if (this.showSubtitles) {
      this.showSubtitles = false;
      sessionStorage.subtitles = 0;
      this.track.mode = 'disabled';
    } else {
      this.showSubtitles = true;
      sessionStorage.subtitles = 1;
      this.track.mode = 'showing';
    }
    this.subtitles.emit(this.showSubtitles);
  }

  onVideoUpdate(): void {
    this.currentTime.emit(this.vidEl.currentTime);
    // emit percent of video played
    this.played.emit(Math.round((this.vidEl.currentTime / this.vidEl.duration) * 1000) * 0.1);
  }

  onVideoPlaying(): void {
    this.playing.emit(true);
    const time = this.vidEl.currentTime;
    const int = setInterval(() => {
      if (time < this.vidEl.currentTime) {
        this.isPaused = false;
        this.paused.emit(false);
        this.waiting.emit(false);
        this.playBtnClass = 'hide';
        clearInterval(int);
      }
    }, 300);
  }

  onVideoWaiting(): void {
    this.waiting.emit(true);
  }

  onVideoEnded(): void {
    this.complete.emit();
    this.paused.emit(true);
    this.isPaused = true;
  }

  onVideoDuration(): void {
    if (this.progressUpdate) {
      clearInterval(this.progressUpdate);
    }
    this.progressUpdate = window.setInterval(() => this.onProgress(), 200);
    this.duration.emit(this.vidEl.duration);
  }

  onProgress(): void {
    this.buffer = null;
    // loop instead of forEach for performance
    for (this.i = 0, this.l = this.vidEl.buffered.length; this.i < this.l; this.i++) {
      this.buffer = this.vidEl.buffered.end(this.i);
    }
    /* Some Androids don't return a buffered range
    fake buffer when no buffered range is returned */
    if (!this.buffer && this.vidEl.currentTime > 0) {
      this.buffer = this.vidEl.currentTime + 2;
    }
    this.percentLoaded = Math.round((this.buffer / this.vidEl.duration) * 1000) * 0.1;
    if (this.percentLoaded >= 100) {
      this.percentLoaded = 100;
      clearInterval(this.progressUpdate);
      this.progressUpdate = null;
    }
    // emit percent of video loaded
    this.loaded.emit(this.percentLoaded);
  }

  onVTTLoaded(e: Event): void {
    // removeAttribute('default') because in safari subtitles will show regardless of setting track.mode to 'disabled'.
    (e.target as HTMLTrackElement).removeAttribute('default');
    this.track = (e.target as HTMLTrackElement).track;
    this.track.mode = parseInt(sessionStorage.subtitles, null) ? 'showing' : 'disabled';
  }

  onVTTLoadError(e: Event): void {
    (e.target as HTMLTrackElement).removeAttribute('default');
    if (this.vidEl.textTracks.length === 2) {
      this.track = this.vidEl.textTracks[1];
      this.track.removeCue(this.track.cues[0] as TextTrackCue);
    } else {
      this.track = this.vidEl.addTextTrack('subtitles', 'Missing', 'en');
    }
    this.track.addCue(new VTTCue(0.1, this.vidEl.duration, 'Subtitles / Closed Captioning not available for this video at this time.'));
    this.track.mode = parseInt(sessionStorage.subtitles, null) ? 'showing' : 'disabled';
  }

}
