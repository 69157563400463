<div class="video-player-wrap" (click)="showPlayBtn ? playToggle() : 'void'">
  <video #video [autoplay]="autoplay" webkit-playsinline="true" playsinline="true"
    (timeupdate)="onVideoUpdate()"
    (playing)="onVideoPlaying()"
    (waiting)="onVideoWaiting()"
    (ended)="onVideoEnded()"
    (loadeddata)="onDataLoaded()"
    (durationchange)="onVideoDuration()">
      <!-- <source *ngIf="stateSource" [attr.src]="stateSource + '.webm'" type="video/webm"> -->
      <source *ngIf="stateSource"[attr.src]="stateSource + '.mp4'" type="video/mp4">
      <!-- <source [attr.src]="source + '.webm'" type="video/webm"> -->
      <source [attr.src]="source + '.mp4'" type="video/mp4">
      <track *ngIf="vttSrc" default kind="subtitles" srclang="en" label="English" id="loaded-vtt" type="text/vtt"
        [attr.src]="vttSrc"
        (load)="onVTTLoaded($event)"
        (error)="onVTTLoadError($event)">
  </video>
  <div *ngIf="showPlayBtn" class="btn-wrap">
    <button class="play-toggle-btn {{ playBtnClass }}" [ngClass]="{paused : !isPaused}"><span></span></button>
  </div>
</div>
