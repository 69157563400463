import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from '../../shared/state';
import * as loadAnimActions from '../../shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';
import { VideoPlayerComponent } from 'src/app/shared/video-player/video-player/video-player.component';
import { VideoUiComponent } from 'src/app/shared/video-player/video-ui/video-ui.component';
import { UserService } from 'src/app/core/services/user.service';
import { OutsideUrls } from 'src/app/core/constants';
import Summaries from './video-summaries.json';

@Component({
  selector: 'app-video-player-shell',
  templateUrl: './video-player-shell.component.html',
  styleUrls: ['./video-player-shell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerShellComponent implements OnInit {

  @ViewChild(VideoPlayerComponent) video: VideoPlayerComponent;
  @ViewChild(VideoUiComponent) videoUiComponent:VideoUiComponent

  src!: string;
  stateSrc!: string;
  vttSrc!: string;
  vttStateSrc!: string;
  nextLink!: string;
  loadedPercent = 0;
  playedPercent = 0;
  currentTime = 0;
  duration!: number;
  ended = false;
  paused = true;
  subtitles = false;
  hasText!: boolean;
  autoplay = true;

  private id!: string;
  private domain!: string;
  private summaries: { id: string, text: string }[] = Summaries;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromShared.State>,
    private userServ: UserService) {}

  ngOnInit(): void {
    this.ended = this.route.snapshot.data.urlData.complete;
    this.hasText = this.route.snapshot.queryParams.txt === 'true';
    console.log("this.route.snapshot", this.route.snapshot)
    this.id = this.route.snapshot.data.urlData.id;
    console.log("his.route.snapshot.data", this.route.snapshot.data)
    this.domain = this.route.snapshot.data.domain;
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.setSource();
  }

  onLoaded(e: number): void {
    this.loadedPercent = e;
  }

  onPlayed(e: number): void {
    this.playedPercent = e;
  }

  onCurrentTime(e: number): void {
    this.currentTime = e;
  }

  onDuration(e: number): void {
    this.duration = e;
    this.store.dispatch(new superModalActions.Reset());
  }

  onComplete(): void {
    this.ended = true;
    const completeUrl = OutsideUrls.COURSE_COMPLETE.replace('$STATE', this.route.snapshot.data.urlData.userType);
    const summary = this.summaries.find(obj => obj.id === this.id);
    //
    if (summary) this.store.dispatch(new superModalActions.HTML(summary.text));
    this.store.dispatch(new superModalActions.Buttons([
      {
        text: 'Play Again',
        icon: 'replay',
        func: () => this.video.playToggle(),
      }, {
        text: 'Continue',
        icon: 'next',
        class: 'green',
        link: this.nextLink !== completeUrl ? this.nextLink : null,
        func: () => this.userServ.activityComplete$.next(true)
      }
    ]));
    this.store.dispatch(new superModalActions.Show());
  }

  onPaused(e: boolean): void {
    this.paused = e;
  }

  onSubtitles(e: boolean): void {
    this.subtitles = e;
  }

  onWaiting(e: boolean): void {
    if (e) {
      this.store.dispatch(new loadAnimActions.Show());
    } else {
      this.store.dispatch(new loadAnimActions.Hide());
    }
  }

  private setSource(): void {
    console.log('this.id', this.id)
    if(this.id === "v-visualization") {
      // added the initial video to this lesson
      this.src = this.domain + "v_guided_mountain";
      this.autoplay = false;
    } else {
      this.src = this.domain + this.id.replace(/-/g, '_');
    }
    console.log('this.src', this.src)
    this.stateSrc = `${this.src}_${this.route.snapshot.data.urlData.userType}`;
  }

  onClickVideo(event): void {
    if(event.srcElement.id === "mountain") {
      this.src = this.domain + "v_guided_mountain";
      this.stateSrc = this.src;
      this.videoUiComponent.onCCBtn();
      this.autoplay = true;
    } else if(event.srcElement.id === "earth") {
      this.src = this.domain + "v_guided_earth";
      this.stateSrc = this.src;
      this.videoUiComponent.onCCBtn();
      this.autoplay = true;
    } else if(event.srcElement.id === "rocks") {
      this.src = this.domain + "v_guided_rocks";
      this.stateSrc = this.src;
      this.videoUiComponent.onCCBtn();
      this.autoplay = true;
    } else if(event.srcElement.id === "garden") {
      this.src = this.domain + "v_guided_garden";
      this.stateSrc = this.src;
      this.videoUiComponent.onCCBtn();
      this.autoplay = true;
    }
  }

}
