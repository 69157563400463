<!-- <app-static [noButton]="true">
</app-static> -->

<div>
<h2 class="title-text">Table of Contents</h2>
<div class="modex-wrap">
  <br>
  <button #btns class="modex-btn" *ngFor="let mod of flow, index as i" 
    [attr.data-status]="mod.status" 
    [disabled]="clicked || mod.status == 'COMPLETE' || mod.status == 'NOT_STARTED'"
    (click)="onClick(i)">
      <h3>{{mod.title}}<br><span></span></h3>
  </button>

</div>
<div class="some-space">
  &nbsp;
</div>
</div>
