<div class="super-modal-wrap" [@super-modalState]="animState" [@super-modalAnim]="animState" (@super-modalAnim.done)="animEnd($event)">

  <div #modal class="super-modal">

    <div #textdiv class="super-modal-txt-wrap" [ngStyle]="{'margin-bottom.em': (title$ | async) || (text$ | async) || (html$ | async) ? 1.5 : null}">

      <h2 #title class="super-modal-title" *ngIf="(title$ | async) || insertedBefore.title">{{ title$ | async }}</h2>

      <p #text class="super-modal-text" *ngIf="(text$ | async) || insertedBefore.text">{{ text$ | async }}</p>

      <section #html class="super-modal-html" *ngIf="(html$ | async) || insertedBefore.html" [innerHTML]="html$ | async | safeHtml"></section>

    </div>

    <div #btns class="btn-wrap">

      <app-button
        *ngFor="let btn of buttons$ | async" 
        [text]="btn.text"
        [id]="btn.id ? btn.id : null" 
        [class]="btn.class"
        [icon]="btn.icon"
        [disabled]="btn.disabled"
        (click)="onBtnClicked(btn)"
        [alt]="true"
        kbDataset [dataset]="btn.data"
      >
      </app-button>
    </div>
  </div>
</div>