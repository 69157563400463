import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { VideoPlayerShellComponent } from './course/video-player-shell/video-player-shell.component';
import { QuizComponent } from './course/quiz/quiz.component';
import { StaticComponent } from './course/static/static.component';
import { SlideRevealComponent } from './course/slide-reveal/slide-reveal.component';
import { SlideRevealAccordionComponent } from './course/slide-reveal-accordion/slide-reveal-accordion.component';
import { ClickRevealComponent } from './course/click-reveal/click-reveal.component';
import { ComicSlidesComponent } from './course/comic-slides/comic-slides.component';
import { SsSidekickComponent } from './course/ss-sidekick/ss-sidekick.component';
import { SwipeActComponent } from './course/swipe-act/swipe-act.component';
import { DrapDropBoxesComponent } from './course/drap-drop-boxes/drap-drop-boxes.component';
import { ModexComponent } from './course/modex/modex.component';
import { ClickSlideshowComponent } from './course/click-slideshow/click-slideshow.component';

export class CustomReuseStrategy implements RouteReuseStrategy {

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    /* console.log('CustomReuseStrategy.shouldDetach'); */
    return !!route.data && !!(route.data as any).shouldDetach;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    /* console.log('CustomReuseStrategy.store'); */
    this.handlers[route.routeConfig.path] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    /* console.log('CustomReuseStrategy.shouldAttach'); */
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    /* console.log('CustomReuseStrategy.retrieve'); */
    if (!route.routeConfig) {
      return null;
    }
    return this.handlers[route.routeConfig.path];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    /* console.log('CustomReuseStrategy.shouldReuseRoute'); */
    return future.routeConfig === curr.routeConfig &&
      ((future.component !== VideoPlayerShellComponent && curr.component !== VideoPlayerShellComponent)
      && (future.component !== QuizComponent && curr.component !== QuizComponent)
      && (future.component !== SlideRevealAccordionComponent && curr.component !== SlideRevealAccordionComponent)
      && (future.component !== SsSidekickComponent && curr.component !== SsSidekickComponent)
      && (future.component !== StaticComponent && curr.component !== StaticComponent)
      && (future.component !== ClickRevealComponent && curr.component !== ClickRevealComponent)
      && (future.component !== ComicSlidesComponent && curr.component !== ComicSlidesComponent)
      && (future.component !== SlideRevealComponent && curr.component !== SlideRevealComponent)
      && (future.component !== DrapDropBoxesComponent && curr.component !== DrapDropBoxesComponent)
      && (future.component !== SwipeActComponent && curr.component !== SwipeActComponent)
      && (future.component !== ModexComponent && curr.component !== ModexComponent)
      && (future.component !== ClickSlideshowComponent && curr.component !== ClickSlideshowComponent));
  }
}
