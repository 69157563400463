import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetLoadService } from 'src/app/core/services/asset-load.service';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import { SSJsonData, SSSlideData } from './models';
import { UserService } from 'src/app/core/services/user.service';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import { gsap } from 'gsap';
import { HTML } from 'src/app/shared/super-modal/state/super-modal.actions';
import * as superModalActions from '../../shared/super-modal/state/super-modal.actions';

@Component({
  selector: 'app-comic-slides',
  templateUrl: './comic-slides.component.html',
  styleUrls: ['./comic-slides.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ComicSlidesComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private assetServ: AssetLoadService,
    private userServ: UserService,
    private store: Store<fromShared.State>
  ) { }

  @ViewChild('slide', { static: false }) private slide: ElementRef;
  @ViewChild('track', { static: false }) private track: ElementRef;

  private curIndex = -1;

  data!: SSJsonData;
  slides!: SSSlideData[];
  curSlide!: SSSlideData;
  allClicked = false;
  nextLink!: string;
  complete!: boolean;
  activeText!: HTML;
  activeBack: boolean;
  activeNext: boolean;
  showOptionsToChoose = false;
  askToChooseOption: boolean;
  correctAnswer: Number;

  ngOnInit(): void {
    console.log(this.route.snapshot.data.json);

    this.data = this.route.snapshot.data.json;
    this.slides = this.data.slides;
    console.log(this.slides);
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.activeBack = false;
    this.activeNext = true;
    this.askToChooseOption = this.route.snapshot.data.json.askToChooseOption
    this.correctAnswer = this.route.snapshot.data.json.correctAnswer
    const manifest = [];
    
    this.slides.forEach((slide, i) => {
      manifest.push({ id: `ss-${this.route.snapshot.data.urlData.id}-${i + 1}`, src: slide.image });
    });
    // start with first slide
    this.curSlide = this.slides[0];
    this.assetServ.loadManifest(manifest).subscribe(() => this.onImagesLoaded())
    if(this.data.instruct) {
      this.instruct();
    }
    console.log("this.curSlide", this.curSlide)
  }

  animate = (direction, index, adjust) => {
    if (direction === 'next') {
      gsap.to(this.slide.nativeElement, {
        duration: 0.5, xPercent: -140, ease: 'power3.out', onComplete: () => {
          this.curSlide = this.slides[this.curIndex];
          setTimeout(() => {
            gsap.fromTo(this.slide.nativeElement,
              { xPercent: 100 },
              { duration: 0.5, xPercent: 0, ease: 'power3.out' });
          }, 100)
        }
      });
    } else {
      gsap.to(this.slide.nativeElement, {
        duration: 0.5, xPercent: 140, ease: 'power3.out', onComplete: () => {
          this.curSlide = this.slides[this.curIndex];
          setTimeout(() => {
            gsap.fromTo(this.slide.nativeElement,
              { xPercent: -100 },
              { duration: 0.5, xPercent: 0, ease: 'power3.out' });
          }, 100)
        }
      });
    }
  }
  nextSlide(): void {
    if (this.activeNext) {
      this.allClicked = false;
      this.curIndex++;
      if (this.curIndex > 0) {
        if (this.curIndex === this.slides.length - 1) {
          this.activeNext = false;
          this.activeBack = true;
          this.showOptionsToChoose = true;
          this.animate('next', this.curIndex, 0)
          // only activate the continue button in last slide for
          // all the non who said it best contents
          if(!this.askToChooseOption) {
            this.complete = true;
          }
        } else {
          this.activeBack = true;
          this.animate('next', this.curIndex, 0)
        }
      }
      else {
        this.curSlide = this.slides[this.curIndex];
        setTimeout(() => {
          gsap.to(this.slide.nativeElement, { duration: 0.5, x: 0, ease: 'power3.out' });
        }, 100);
      }
    }
    this.activeText = this.slides[this.curIndex].text
  }

  previousSlide(): void {
    if (this.curIndex === 1) {
      console.log(this.curIndex)
      this.activeBack = false;
    }
    if (this.curIndex === this.slides.length - 1) {
      console.log(this.curIndex)
      this.showOptionsToChoose = false;
      this.activeNext = true
    }
    if (this.curIndex > 0) {
      console.log(this.curIndex)
      this.animate('back', this.curIndex, 0)
      this.curIndex--
    }
    this.activeText = this.slides[this.curIndex].text
  }

  private onImagesLoaded(): void {
    this.store.dispatch(new loadAnimActions.Hide());
    this.nextSlide();
  }

  // check the right answer for the who said it best activity
  clickToCheckAnswer(option) {
    if (this.correctAnswer === option) { 
      this.store.dispatch(new superModalActions.Title('Correct'))
      this.store.dispatch(new superModalActions.HTML(this.data.caregiverCorrectFeedback))
      this.store.dispatch(new superModalActions.Buttons([{
        text: 'Ok',
        icon: 'next'
      }]));
      this.store.dispatch(new superModalActions.Show(0.6))
      this.complete = true;
    } else {
      this.store.dispatch(new superModalActions.Title('Incorrect'))
      this.store.dispatch(new superModalActions.HTML(this.data.caregiverWrongFeedback))
      this.store.dispatch(new superModalActions.Buttons([{
        text: 'Ok',
        icon: 'next'
      }]));
      this.store.dispatch(new superModalActions.Show(0.6))
    }
  }

  onContinue(): void {
    this.userServ.activityComplete$.next(true);
  }

  private instruct(): void {
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.Title('Instructions'))
    this.store.dispatch(new superModalActions.HTML(this.data.instruct))
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'Begin',
      icon: 'next'
    }]));
    this.store.dispatch(new superModalActions.Show(0.6))
  }

}
