import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, style, animate, transition, state, AnimationEvent } from '@angular/animations';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromShared from '../state';
import { LoaderAnimComponent } from '../loader-anim/loader-anim.component';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-loading-anim',
  template: `
    <div class="load-anim-wrap" #wrap>
      <div class="load-anim" [@loadingAnim]="animState" (@loadingAnim.start)="onLoadingAnimStart($event)" (@loadingAnim.done)="onLoadingAnimDone($event)">
        <app-loader-anim [href]="href"></app-loader-anim>
      </div>
    </div>`,
  styleUrls: ['./loading-anim.component.scss'],
  animations: [
    trigger('loadingAnim', [
      state('show', style({
        opacity: '1',
        visibility: 'visible'
      })),
      state('hide', style({
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('show => hide', animate('600ms 1s ease-out')),
      transition('hide => show', animate('600ms 500ms ease-out')),
    ])
  ]
})
export class LoadingAnimComponent implements OnInit {

  @ViewChild(LoaderAnimComponent) anim: LoaderAnimComponent;
  @ViewChild('wrap') wrapper!: ElementRef;

  animState!: string;
  href: string;
  private show$!: Observable<boolean>;

  constructor(private store: Store<fromShared.State>, private router: Router) {
    this.href = window.location.pathname;
  }

  ngOnInit() {
    this.show$ = this.store.pipe(select(fromShared.getLoadAnimShow));
    this.show$.subscribe(boo => {
      if (boo && this.animState !== 'show') {
        this.animState = 'show';
      } else if (this.animState !== 'hide') {
        this.animState = 'hide';
      }
    });
    //
    this.router.events.subscribe( e => {
      if (e instanceof NavigationEnd) {
        this.href = window.location.pathname;
      }
    });
  }

  onLoadingAnimStart(e: AnimationEvent) {
    if (this.animState === 'show') {
      this.wrapper.nativeElement.style.display = 'flex';
      this.anim.play();
    }
  }

  onLoadingAnimDone(e: AnimationEvent) {
    if (this.animState === 'hide') {
      this.wrapper.nativeElement.style.display = 'none';
      this.anim.pause();
    }
  }
}
