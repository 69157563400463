import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition, state, AnimationEvent } from '@angular/animations';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { UserService } from './core/services/user.service';
import { CustomError, ModMenuData, ClickStreamData } from './core/models';
import * as fromApp from './state';
import * as fromSidebar from './core/sidebar/state';
import * as sidebarActions from './core/sidebar/state/sidebar.actions';
import * as loadAnimActions from './shared/loading-anim/state/loading-anim.actions';
import { environment } from 'src/environments/environment';
import { handleCustomError } from './core/error-handler/handle-custom-error';
import { FlowService } from './core/services/flow.service';
import * as superModalActions from '../app/shared/super-modal/state/super-modal.actions';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  animations: [
    trigger('sidebarAnim', [
      state('min', style({
        transform: 'translateX(81.25%)'
      })),
      state('max', style({
        transform: 'translateX(0%)'
      })),
      transition('min => max', animate('300ms ease-out')),
      transition('max => min', animate('300ms ease-out')),
    ])
  ]
})
export class AppComponent implements OnInit {

  layoutState = 'max'; // 'min'
  menuState = 'open';
  showHelp = false;

  private interval: any;
  private errorMessage$: Observable<CustomError>;
  private modules$: Observable<ModMenuData[]>;
  private modSub: Subscription;

  idleState = 'Not started.';
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  constructor(
    private router: Router,
    private userServ: UserService,
    private flowServ: FlowService,
    private idle: Idle, 
    private keepalive: Keepalive,
    private store: Store<fromApp.State>
  ) {
    // sets an idle timeout of 30 mins
    idle.setIdle(1800);
    // sets a timeout period of 20 seconds. after 20 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(20);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      this.store.dispatch(new superModalActions.HTML(`<h2>${this.idleState}</h2>`))
      console.log(this.idleState);
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.userServ.timedOut = true;
      this.store.dispatch(new superModalActions.HTML(`<h2>You Have Been Idle!</h2><br><p style="text-align:center">${this.idleState}</p>`))
      console.log(this.idleState);
      this.logout();
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      this.store.dispatch(new superModalActions.HTML(`<h2>You Have Been Idle!</h2><br><p style="text-align:center">${this.idleState}</p>`))
        this.store.dispatch(new superModalActions.Buttons([
            {
              text: 'Logout',
              icon: 'close',
              func: () => this.logout()
            },
            {
              text: 'Stay',
              icon: 'next',
              func: () => this.reset()
            }
          ])
        )
        this.store.dispatch(new superModalActions.Show());
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
   }

  ngOnInit(): void {

    const startState = sessionStorage.getItem('layoutState');
    if (startState && startState !== this.layoutState) {
      this.layoutToggle();
    }
    //
    this.errorMessage$ = this.store.pipe(select(fromSidebar.getError));
    this.errorMessage$.subscribe(err => handleCustomError(err, this.router, this.store, this.userServ));
    //
    this.modules$ = this.store.pipe(select(fromSidebar.getFlow));
    this.modSub = this.modules$.subscribe(dta => this.onModules(dta));
    //
    this.userServ.activityComplete$.subscribe(boo => this.onActivityComplete(boo));
    //this.userServ.clickStream$.subscribe(dta => this.onClickStreamData(dta));

    if(sessionStorage.token) {
      //this.userServ.getProgress().then(() => this.store.dispatch(new sidebarActions.Load()));
    }
  }

  layoutToggle(): void {
    if (this.layoutState === 'min') {
      this.layoutState = 'max';
      this.menuState = 'open';
    } else {
      this.layoutState = 'min';
      this.menuState = 'closed';
    }
    sessionStorage.setItem('layoutState', this.layoutState);
  }

  onAnimStart(e: AnimationEvent): void {
    this.interval = setInterval(() => window.dispatchEvent(new Event('resize')), 17);
  }

  onAnimDone(e: AnimationEvent): void {
    clearInterval(this.interval);
    window.dispatchEvent(new Event('resize'));
  }

  onHelp(): void {
    this.showHelp = this.showHelp ? false : true;
  }

  private onModules(dta: ModMenuData[]): void {
    /* console.log('AppComponent.modules$ updated', dta); */
    if (dta) {
      this.store.dispatch(new loadAnimActions.Hide());
      this.modSub.unsubscribe();
    }
  }

  private onActivityComplete(boo: boolean): void {
    const progress = this.userServ.onActComplete(boo);
    if (progress) {
      this.userServ.updateProgress(progress).subscribe(
        () => { if (!environment.production) console.log('User Progress update success'); },
        err => { if (environment.production) handleCustomError(err, this.router, this.store, this.userServ); }
      );
    }
  }

  private onClickStreamData(dta: ClickStreamData): void {
    if (dta && environment.production) {
      this.userServ.postClickStream({ enter: dta.enter, url: dta.url }).subscribe(
        () => {
          if (!environment.production) console.log(`post click stream success (${dta.enter ? 'enter' : 'leave'})`, dta);
          if (dta.redirect) window.location.pathname = dta.redirect;
        },
        err => {
          if (dta.redirect) window.location.pathname = dta.redirect;
          handleCustomError(err, this.router, this.store, this.userServ);
        }
      );
    } else if (dta) {
      /* log for dev */
      // console.log(`click stream (${dta.enter ? 'enter' : 'leave'})`, dta);
    }
  }

  reset() {
    this.idle.watch();
    //this.idleState = 'Started.';
    this.userServ.timedOut = false;
  }

  logout() {
      this.userServ.clearCookie();
      sessionStorage.removeItem("token");
      this.userServ.loggedIn$.next(false);
      this.userServ.showMenu$.next(false);
      this.router.navigateByUrl(`/`);
      this.idle.stop();
  }
}
