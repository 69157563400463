import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';

export interface SwipeMoveData {
  startX: number;
  lastX: number;
  difX: number;
  startY: number;
  lastY: number;
  difY: number;
}

export enum SwipeTypes {
  Left = 'SWIPE_LEFT',
  Right = 'SWIPE_RIGHT',
  Up = 'SWIPE_UP',
  Down = 'SWIPE_DOWN'
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[kbSwipe]'
})
export class SwipeDirective {

  @Output() swipe = new EventEmitter<string>();
  @Output() swipeMove = new EventEmitter<SwipeMoveData>();
  @Input() swipeThresh = 200;

  private startX: number;
  private startY: number;
  private endX: number;
  private endY: number;
  private difX: number;
  private difY: number;
  private lastX: number;
  private lastY: number;

  constructor() { }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e: any) {
    /* console.log('touchstart', e); */
    if (e.changedTouches.length > 0) {
      this.startX = e.changedTouches[0].clientX;
      this.startY = e.changedTouches[0].clientY;
      this.lastX = this.startX;
      this.lastY = this.startY;
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(e: any) {
    /* console.log('touchend', e); */
    if (e.changedTouches.length > 0) {
      this.endX = e.changedTouches[0].clientX;
      this.endY = e.changedTouches[0].clientY;
      this.difX = this.startX - this.endX;
      this.difY = this.startY - this.endY;
      /* console.log(this.difX, this.difY); */
      if (Math.abs(this.difX) > this.swipeThresh) {
        this.swipe.emit(this.difX > 0 ? SwipeTypes.Left : SwipeTypes.Right);
        /* return false; */
      } else if (Math.abs(this.difY) > this.swipeThresh) {
        this.swipe.emit(this.difY > 0 ? SwipeTypes.Up : SwipeTypes.Down);
        /* return false; */
      }
    }
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(e: any) {
    /* console.log('touchstart', e); */
    if (e.changedTouches.length > 0) {
      this.swipeMove.emit({
        startX: this.startX,
        lastX: this.lastX,
        difX: this.lastX - e.changedTouches[0].clientX,
        startY: this.startY,
        lastY: this.lastY,
        difY: this.lastY - e.changedTouches[0].clientY
      });
      this.lastX = e.changedTouches[0].clientX;
      this.lastY = e.changedTouches[0].clientY;
    }
  }

}
