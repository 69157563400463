import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModMenuData } from '../../models';

@Component({
  selector: 'app-mod-menu',
  templateUrl: './mod-menu.component.html',
  styleUrls: ['./mod-menu.component.scss']
})
export class ModMenuComponent implements OnInit {

  @Input() data: ModMenuData;
  @Input() index: number;

  state = 'closed';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      // console.log(e);
      if (e instanceof NavigationEnd) {
        const url = '/' + decodeURIComponent(e.url.substr(1).split('?')[0]);
        this.data.links.some((link): any => {
          /* open menu if current location is in menu */
          if (url === link.url) {
            this.state = 'open';
            return true;
          } else {
            this.state = 'closed';
            return false;
          }
        });
      }
    });
  }

  onModMenuClick(): void {
    if (this.state === 'closed') {
      this.state = 'open';
    } else {
      this.state = 'closed';
    }
  }

}
