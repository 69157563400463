<div class="clickslideshow-wrap">
  <div class="text-wrap">
    <h2>{{title}}</h2>
    <div [innerHTML]="description">{{description}}</div>
  </div>
  <div class="slides-wrap" (click)="onClick($event)">
    <div class="instruction" [innerHTML]="instruct">
    </div>
    <div *ngIf="image" class="image-wrapper">
      <img src="{{image}}" style="margin:0 1rem;" />
    </div>
    <ng-container *ngFor="let list of lists">
      <h3 [id]="list.id">{{list.title}}</h3>
      <ul>
      <ng-container *ngFor="let bullet of list.bullets">
      <li [id]="bullet.id" [ngClass]="bullet.reveal ? 'reveal' : 'hidden'"><span class="placeholder">Click to reveal</span>{{bullet.text}}</li>
        <ng-container *ngIf="bullet.subContents">
            <ng-container *ngFor="let subContent of bullet.subContents">
              <div [id]="subContent.id" [ngClass]="subContent.reveal ? 'reveal' : 'hidden'">
                <h4><span class="placeholder">Click to reveal</span>{{subContent.title}}</h4>
                <ul>
                  <li>{{subContent.text}}</li>
                </ul>
              </div>
            </ng-container>
        </ng-container>
      </ng-container>
      </ul>
      
    </ng-container>
  </div>
  <div class="lastWords" *ngIf="lastWords">{{lastWords}}</div>
  <div class="btn-wrap">
    <app-button text="Continue" [disabled]="!complete" icon="next" alt="true" [link]="nextLink" (click)="onContinue()"></app-button>
  </div>
</div>
