import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { SwipeTypes } from 'src/app/shared/directives/swipe.directive';

@Component({
  selector: 'app-slide-show-arrow',
  template: '<div class="arrow" (click)="onClick()" (touchend)="onClick()"></div>',
  styleUrls: ['./slide-show-arrow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlideShowArrowComponent {

  @Input() direction: string;
  @Output() arrowEvent = new EventEmitter<string>();

  constructor() { }

  onClick() {
    if (this.direction === 'right') {
      this.arrowEvent.emit(SwipeTypes.Left);
    } else if (this.direction === 'left') {
      this.arrowEvent.emit(SwipeTypes.Right);
    }
    //
    return false;
  }

}
