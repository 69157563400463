import { Component, ViewChildren, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { gsap, Draggable } from 'gsap/all';

@Component({
  selector: 'app-loader-anim',
  templateUrl: './loader-anim.component.html',
  styleUrls: ['./loader-anim.component.scss']
})
export class LoaderAnimComponent implements AfterViewInit {
  /* @ViewChildren('circles') private circles: any;
  @ViewChild('wLine') private wLine!: ElementRef;
  @ViewChild('tLine') private tLine!: ElementRef;
  @ViewChild('sLine') private sLine!: ElementRef; */
  @Input() href!: string;

  private tl!: gsap.core.Timeline;
  private tl2!: gsap.core.Timeline;

  constructor() { }

  ngAfterViewInit(): void {
    this.buildTL();
  }

  play(): void {
    /* this.tl.play();
    this.tl2.play(); */
  }

  pause(): void {
    /* this.tl.pause();
    this.tl2.pause(); */
  }

  private buildTL(): void {

  }
}
