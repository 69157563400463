import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromShared from '../../state';
import * as loadAnimActions from '../../loading-anim/state/loading-anim.actions';
import { AssetLoadService } from 'src/app/core/services/asset-load.service';
import { LoadItemData, AssetData } from 'src/app/core/models';
import { OutsideUrls } from 'src/app/core/constants';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  images = new Subject<HTMLImageElement[]>();
  showing = new Subject<boolean>();
  autoClose = new Subject<null>();

  private count: number;
  private imageArr: HTMLImageElement[];
  private _curID: string;

  get curID(): string {
    return this._curID;
  }

  set curID(id: string) {
    this._curID = id;
    this.autoClose.next();
  }

  constructor(private assetServ: AssetLoadService, private store: Store<fromShared.State>) { }

  loadImages() {
    if (this.curID) {
      this.store.dispatch(new loadAnimActions.Show());
      this.count = 0;
      this.imageArr = [];
      this.loadImage();
    }
  }

  private loadImage() {
    this.count++;
    const id = `help-${this.curID}-${this.count}`;
    const manifest: LoadItemData[] = [
      {
        id: id,
        src: `${OutsideUrls.HELP}${this.curID}/${this.curID}-help${this.count}.jpg`
      }
    ];
    this.assetServ.loadManifest(manifest).subscribe(results => this.onImageLoaded(results, id));
  }

  private onImageLoaded(results: AssetData, id: string) {
    if (results[id]) {
      this.imageArr.push(results[id]);
      this.loadImage();
    } else {
      this.onAllLoaded();
    }
  }

  private onAllLoaded() {
    this.store.dispatch(new loadAnimActions.Hide());
    this.images.next(this.imageArr);
  }
}
