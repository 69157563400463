<div class="help-wrap" [ngClass]="{'show': show}">

  <div class="btn-wrap">
    <h1>{{ helpTxt }}</h1>
    <app-button [text]="closeTxt" icon="close" (click)="close()" [alt]="true">
    </app-button>
  </div>

  <app-slide-show-arrow direction="left" (arrowEvent)="onSwipe($event)"></app-slide-show-arrow>

  <div class="slides-wrap">
    <div #holder kbSwipe class="slides-holder"
      [ngClass]="{'no-slides': imageArr && imageArr.length === 0}"
      [ngStyle]="{'width.%': imageArr && imageArr.length > 0 ? imageArr.length * 100 : 100 }"
      (swipe)="onSwipe($event)"
      (swipeMove)="onSwipeMove($event)" (touchend)="onTouchEnd()">
      <img *ngFor="let image of imageArr" [src]="image.src">
      <h2 *ngIf="imageArr && imageArr.length === 0" class="no-slides">{{ noHelp }}</h2>
    </div>
  </div>

  <app-slide-show-arrow direction="right" (arrowEvent)="onSwipe($event)"></app-slide-show-arrow>

  <div class="help-dots-wrap">
    <app-correct-dots [fills]="fillArr" [(current)]="curIndex" [allowClick]="true"></app-correct-dots>
  </div>
  
</div>
