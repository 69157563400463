import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { handleCustomError } from './handle-custom-error';
import { CustomError } from '../models';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  handleError(error: CustomError): void {
    if (!environment.production) console.log('ErrorHandlerService.handleError()', error);
    if (error && error.status) {
      const router = this.inj.get(Router);
      const store = this.inj.get(Store);
      const userServ = this.inj.get(UserService);
      this.zone.run(() => {
        handleCustomError(error, router, store, userServ);
      });
    }
  }

  constructor(
    private inj: Injector,
    private zone: NgZone
  ) { }

}
