import { Component, OnChanges, Input, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { ShowData } from '../models';

// ****EXAMPLE OF USE AND PROPS****
// <app-note
//   ="Header For This Note"  //Title for Note
//   subText="Please click [NEXT] to continue"  //Sub-Text for Note
//   direction="left"  //Position of Arrow on Note Accepts: top, bottom, right, left -- Defaults to Center of declared Side
//   position="lower"  //Position of Arrow on Declared Side -- If blank defaults to Center -- Otherwise accepts: 'left' and 'right' (for 'top' and 'bottom' sides) and 'upper' and 'lower' (for 'left' and 'right sides')
//   >
// </app-note>

@Component({
  selector: 'app-note',
  template: `
    <div
      id="note-container"
      class="note-container note-container-{{ direction }} {{ position ? getPosition(position) : '' }}"
      [ngStyle]="{borderRadius: borderRadius}"
      [@noteState]="state"
      [@noteAnim]="state"
    >
      <div class="note-txt" [ngStyle]="{ fontSize: fontSize, color: color }">
        <section class="note-sub-txt" [innerHTML]="text | safeHtml"></section>
      </div>
    </div>
  `,
  styleUrls: ['./note.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('noteState', [
      state('hide', style({
        opacity: 0,
        display: 'none'
      })),
      state('show', style({
        opacity: 1,
        display: 'flex'
      })),
      transition('hide => show', animate('200ms linear')),
      transition('show => hide', animate('200ms 200ms linear')),
    ]),
    trigger('noteAnim', [
      transition('hide => show', [
        style({ transform: 'scale(0.8, 0.8)' }),
        animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'scale(1, 1)' }))
      ]),
      transition('show => hide', [
        style({ transform: 'scale(1, 1)' }),
        animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)', style({ transform: 'scale(0.8, 0.8)' }))
      ]),
    ])
  ]
})

export class NoteComponent implements OnChanges {
  @Input() text: string;
  @Input() direction: string;
  @Input() position: string;
  @Input() color: string;
  @Input() fontSize: string;
  @Input() borderRadius: string;
  @Input() showHide: boolean | ShowData;

  state = 'hide';
  constructor() { }

  ngOnChanges(e: SimpleChanges) {
    if (e.showHide) {
      if (typeof this.showHide === 'boolean') {
        if (this.showHide) {
          this.show();
        } else {
          this.hide();
        }
      } else {
        if (this.showHide.show) {
          this.show(this.showHide.delay);
        } else {
          this.hide(this.showHide.delay);
        }
      }
    }
  }

  getPosition(position) {
    switch (position) {
      case 'left':
        position = 'note-container-l';
        break;
      case 'right':
        position = 'note-container-r';
        break;
      case 'upper':
        position = 'note-container-t';
        break;
      case 'lower':
        position = 'note-container-b';
        break;
    }
    return position;
  }
  //
  show(delay?: number): NoteComponent {
    if (!delay) {
      this.state = 'show';
    } else {
      setTimeout(() => {
        this.state = 'show';
      }, delay * 1000);
    }
    return this;
  }
  //
  hide(delay?: number): NoteComponent {
    if (!delay) {
      this.state = 'hide';
    } else {
      setTimeout(() => {
        this.state = 'hide';
      }, delay * 1000);
    }
    return this;
  }

}
