import { Injectable } from '@angular/core';
import { UserProgress, LoginData, ModMenuData, UserData, CustomError, ClickStreamData, CurrentModule, QuizReportData, QuizReportResponse, UserInfo, Progress, ProgressData, securityAnswer } from '../models';
import { MenuStatusTypes, OutsideUrls, ErrorMessages, SESSION_MINUTES } from '../constants';
import { BehaviorSubject, Observable, throwError, Subject, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RegisterData, SubmitData } from 'src/app/core/models';
import { BaseService } from './base-service';
import { StorageService } from './storage.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/shared/state';
import * as sidebarActions from '../sidebar/state/sidebar.actions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedIn$ = new BehaviorSubject<boolean>(false);
  progress$ = new BehaviorSubject<UserProgress>(null); // subbed in flow.service, next() here in user.service
  progressData$ = new BehaviorSubject<ProgressData>(null);
  activityComplete$ = new BehaviorSubject<boolean>(false); // subbed in app.component
  showMenu$ = new BehaviorSubject<boolean>(false); // subbed in user-menu.component, next() in sidebar.component
  hasError$ = new Subject<boolean>(); // subbed in auth-gaurd.service, next() in flow.service & user.service
  flow: ModMenuData[];
  username$ = new BehaviorSubject<string>(null); // subbed in user-menu.component
  clickStream$ = new Subject<ClickStreamData>(); // subbfed in app.component, next() in course.component & user-menu.component
  currentModule$ = new BehaviorSubject<string>(null);
  currentModuleStartTime$ = new BehaviorSubject<Date>(null);
  pId$ = new BehaviorSubject<string>(null);

  timedOut = false;

  private cookiePrefix = 'app-validate=';

  // tslint:disable-next-line: variable-name
  private _userType: string;
  get userType(): string {
    return this._userType;
  }

  constructor(
    private loc: Location, 
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private baseService: BaseService,
    private store: Store<State>) { }

  isLoggedIn(updateCookie: boolean): boolean {
    const currentCookie = this.getCookie();
    const token = sessionStorage.token;
    console.log(currentCookie);
    console.log(sessionStorage.storedCookie)
    if (!currentCookie && !token && sessionStorage.storedCookie || currentCookie !== sessionStorage.storedCookie) {
      if (currentCookie) this.clearCookie();
      this.loggedIn$.next(false);
      this.router.navigateByUrl(`/`)
      return false;
    } else {
      if (updateCookie) {
        this.makeCookie();
        this.loggedIn$.next(true);
      }
      return true;
    }
  }

  login(creds: LoginData): Observable<UserInfo> {
    // return this.baseService.post<UserInfo>('api/login', creds).pipe(
    //   tap(dta => {
    //       console.log('dta',dta)
    //       if(dta.token) {
    //         this.loggedIn$.next(true);
    //         this.storage.save('token', dta.token, true)
    //         sessionStorage.setItem('token', dta.token)
    //         sessionStorage.setItem('name', dta.name)
    //         sessionStorage.setItem('role', dta.role)
    //         sessionStorage.setItem('pId', creds.prisonerid)
    //         this.getProgress();
            
    //       }
    //     }),
    //   tap(() => this.hasError$.next(false)),
    //   catchError(err => this.handleError(err, ErrorMessages.LOGIN))
    // );
    this.loggedIn$.next(true);
    let success = true;
    // remove this when connecting to backend the type will be recevied in the login response. 
    if (success) {
      sessionStorage.setItem('username', 'username')
      sessionStorage.setItem('validationKey', 'I would be a backend response key')
      sessionStorage.setItem('type', 'prisoner')
      sessionStorage.setItem('progress', JSON.stringify({"curModIndex": 1,"linkIndexes": 1}))
      sessionStorage.setItem('token', 'something')
      this.progress$.next({"curModIndex": 1,"linkIndexes": 1})
      this.loadUserData().subscribe(() => this.store.dispatch(new sidebarActions.Load()));
      this.hasError$.next(false)
    } else {
      // error
    }
    return null;
    // return this.http.post<UserInfo>('api/login', creds).pipe(
    //   tap(dta => this.onLogin(dta)),
    //   tap(() => this.hasError$.next(false)),
    //   catchError(err => this.handleError(err, ErrorMessages.LOGIN))
    // );

  }

  async getProgress() {
    await this.baseService.getProgressData<ProgressData>('api/progress').toPromise()
    .then(
      data =>  {
      {
        console.log('data', data)
        sessionStorage.setItem('progress', JSON.stringify(data))
        this._userType = "a";
        this.username$.next(sessionStorage.getItem('name'));
        this.pId$.next(sessionStorage.getItem('pId'))
        this.makeCookie();
        this.hasError$.next(false);
        this.progressData$.next(data);
        this.store.dispatch(new sidebarActions.Load());
        console.log('this.flow', this.flow)
        console.log("this.progressData$", this.progressData$)
        return data;
     }
      })
  }

  updateProgress(progress: UserProgress): Observable<void> {
    return this.baseService.post<void>('api/progress', progress)
      .pipe(
        tap(() => {
          this.hasError$.next(false)
          if(progress) {
            const text =  JSON.stringify(progress)
            sessionStorage.setItem('progress', text)
          }
        }),
        catchError(err => this.handleError(err, ErrorMessages.USER_PROGRESS))
      );
  }

  register(regData: SubmitData): Observable<void> {
    console.log(regData);

    return this.baseService.post<void>('api/register', regData).pipe(
      retry(2),
      catchError(err => this.handleError(err, 'Unable to create new user account.'))
    );
  }

  checkSecurityQuestion(data: securityAnswer): Observable<void> {
    return this.baseService.post<void>('api/login/password', data)
      .pipe(
        tap((pw) => {
          this.hasError$.next(false)
          console.log('pw', pw)
        }),
        catchError(err => {
          console.log('err', err)
          if(err.status === 401) {
            return this.handleError(err, ErrorMessages.UNAUTHORIZED)
          } else if (err.status === 400 && data.qId === 3) {
            return this.handleError(err, ErrorMessages.UNAUTHORIZED)
          }else {
            return this.handleError(err, ErrorMessages.SECURITY_QUESTION)
          }
        })
      );
  }

  grabSecurityQA(username: String) {
    // return an object with q1-5 a1-5 
    // backend will need to grab the actual values but for now I will use a set of placholder data
    console.log(username);
    let data = {
      "question1": "What is your favorite ice cream flavor?",
      "question2": "What is your favorite drink?",
      "question3": "What is your favorite color?"
    }
    return data
  }
  onLogin(data) {
    if (!data) {
      this.loggedIn$.next(false);
    } else {
      this.loggedIn$.next(true);
      this.router.navigateByUrl(`course/a/intro/video/v-intro`);
    }


  }

  

  moduleSeatTime(startTime: Date, timeEnd: Date, endingModule) {
    console.log(startTime, timeEnd, endingModule);
  }

  calculateSeatTime(startTime: Date, endTime: Date, moduleName: string) {
    console.log(moduleName, startTime, endTime);
  }



  postSeatTime(dta) {
    let payload = dta;
    payload.endTime = new Date();
    console.log(payload)
    // post payload to backend
  }

  loadUserData(): Observable<UserData> {
    return this.http.get<UserData>(OutsideUrls.USER_PROGRESS_TEMP, {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    })
      .pipe(
        tap((data: UserData) => {
          /* console.log('user data loaded'); */
          console.log('data', data)
          console.log("this.progress$", this.progress$)
          this._userType = data.type;
          console.log(this._userType)
          this.username$.next(sessionStorage.getItem('username'));
          this.makeCookie();
          this.hasError$.next(false);
          this.progress$.next(data.progress);
        }),
        catchError(err => this.handleError(err, ErrorMessages.USER_PROGRESS))
      );
  }

  postClickStream(data: ClickStreamData): Observable<void> {
    return this.http.post<void>('api/user/activity', data)
      .pipe(
        tap(() => this.hasError$.next(false)),
        catchError(err => this.handleError(err, ErrorMessages.USER_PROGRESS))
      );
  }

  /**
   * Backend will track how many tries for post-test and returned data will inform if module needs to be restarted.
   * @param data {@link QuizReportData}
   * @returns {@link QuizReportResponse}
   */
  postQuizResults(data: QuizReportData): Observable<QuizReportResponse> {
    return !environment.production ? of({ passed: data.passed, restart: false }) : this.http.post<QuizReportResponse>('api/user/quiz', data)
      .pipe(
        tap(() => this.hasError$.next(false)),
        catchError(err => this.handleError(err, ErrorMessages.QUIZ))
      );
  }

  onActComplete(complete: boolean): UserProgress | void {
    if (complete) {
      const prog = this.progress$.getValue();
      const url = this.flow[prog.curModIndex].links[prog.linkIndexes].url;
      console.log('this.flow', this.flow)
     //console.log("this.progressData$", this.progressData$)
      //
      if ((this.loc.isCurrentPathEqualTo(url) || this.loc.isCurrentPathEqualTo('/' + url)) &&
        this.flow[prog.curModIndex].links[prog.linkIndexes].status === MenuStatusTypes.IN_PROGRESS) {
        prog.linkIndexes++;
        this.progress$.next(prog);
        return prog;
      }
    } else {
      return;
    }
  }

  clearCookie(): void {
    /* console.log('clearCookie()'); */
    sessionStorage.clear();
    document.cookie = `${this.cookiePrefix};max-age=1;path=/`;
  }

  private handleError(err: HttpErrorResponse, msg: string): Observable<never> {
    console.log('err', err)
    console.log('msg', msg)
    const error: CustomError = {
      status: err.status,
      message: msg
    };
    this.hasError$.next(true);
    return throwError(error);
  }

  private getCookie(): string {
    console.log('getCookie()');
    const ca: string[] = document.cookie.split(';');
    let returnedCookie: string;
    ca.forEach((cookie: string) => {
      while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
      if (cookie.indexOf(this.cookiePrefix) === 0) returnedCookie = cookie.substring(this.cookiePrefix.length, cookie.length);
    });
    return returnedCookie;
  }

  private makeCookie(): void {
    /* console.log('makeCookie()'); */
    const date = new Date();
    const cookieData = date.getTime() + '.' + btoa(this.username$.getValue());
    const maxage = 'max-age=' + (SESSION_MINUTES * 60); // allow 60 min (SESSION_MINUTES) per page before session ends
    /* let expire: string;
    date.setTime(date.getTime() + (SESSION_MINUTES * 60 * 1000));
    expire = 'expires=' + date.toUTCString(); */
    document.cookie = `${this.cookiePrefix}${cookieData};${maxage};path=/`;
    sessionStorage.storedCookie = cookieData;
  }
}
