import { Action } from '@ngrx/store';
import { ModMenuData, CustomError } from '../../models';

export enum SidebarActionTypes {
  Load = '[Sidebar] Load Flow',
  LoadSuccess = '[Sidebar] Load Flow Success',
  LoadFail = '[Sidebar] Load Flow Fail',
  Update = '[Sidebar] Update Flow',
  UpdateSuccess = '[Sidebar] Update Flow Success',
  UpdateFail = '[Sidebar] Update Flow Fail'
}

export class Load implements Action {
  readonly type = SidebarActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = SidebarActionTypes.LoadSuccess;

  constructor(public payload: ModMenuData[]) { }
}

export class LoadFail implements Action {
  readonly type = SidebarActionTypes.LoadFail;

  constructor(public payload: CustomError) { }
}

export class Update implements Action {
  readonly type = SidebarActionTypes.Update;

  constructor(public payload: ModMenuData[]) { }
}

export class UpdateSuccess implements Action {
  readonly type = SidebarActionTypes.UpdateSuccess;

  constructor(public payload: ModMenuData[]) { }
}

export class UpdateFail implements Action {
  readonly type = SidebarActionTypes.UpdateFail;

  constructor(public payload: CustomError) { }
}

export type SidebarActions = Load
  | LoadSuccess
  | LoadFail
  | Update
  | UpdateSuccess
  | UpdateFail;

