import { LoadingAnimActions, LoadingAnimActionTypes } from './loading-anim.actions';

export interface LoadingAnimState {
  show: boolean;
}

const initState: LoadingAnimState = {
  show: true
};

export function reducer(state = initState, action: LoadingAnimActions): LoadingAnimState {

  switch (action.type) {

    case LoadingAnimActionTypes.Show:
      return {
        show: true
      };

    case LoadingAnimActionTypes.Hide:
      return {
        show: false
      };

    default:
      return state;
  }
}

