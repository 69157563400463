import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '../../state';
import * as fromSidebar from './state';
import { ModMenuData } from '../models';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  @Input() sidebarState: string;
  modules$: Observable<ModMenuData[]>;
  modules: ModMenuData[];

  constructor(private store: Store<State>, private userServ: UserService) { }

  ngOnInit(): void {
    this.modules$ = this.store.pipe(
      select(fromSidebar.getFlow),
      map(mods => !mods ? mods : mods.map(mod => {
        const links = mod.links.map(link => {
          const split = link.url.split('?');
          const url = split[0];
          const params = split[1] ? {} : null;
          if (params) {
            const s = split[1].split('=');
            params[s[0]] = s[1];
          }
          return { ...link, url, params };
        });
        return { ...mod, links };
      }))
    );
    this.modules$.subscribe(dta => {
      console.log('dta',dta)
      let new_dta;
      console.log('sessionstorage',sessionStorage)
      if(dta) {
        if(sessionStorage.role == 1) {
          new_dta = dta.filter(data => data.id !== 7)
          console.log('new_dta',new_dta)
        } else {
          new_dta = dta;
        }
      }
      if (!this.modules) {
        this.modules = new_dta;
      } else {
        new_dta.forEach((mod, i) => {
          this.modules[i].progress = mod.progress;
          this.modules[i].status = mod.status;
          mod.links.forEach((link, n) => {
            this.modules[i].links[n].status = link.status;
          });
        });
      }
    });
  }

  onUserIcon(): void {
    this.userServ.showMenu$.next(this.userServ.showMenu$.getValue() ? false : true);
  }

}
