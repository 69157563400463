/// <reference path="../../../../node_modules/@types/preloadjs/index.d.ts" />
/// <reference path="../../../../node_modules/@types/soundjs/index.d.ts" />
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, fromEvent, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'latest-createjs/lib/preloadjs/preloadjs';
import 'latest-createjs/lib/soundjs/soundjs';
import { CustomError, LoadItemData, AssetData, LoadItemEvent } from '../models';
import { ErrorMessages } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AssetLoadService {

  private queue: createjs.LoadQueue;
  private jsonCache = {};
  private soundInstalled = false;

  constructor(private http: HttpClient) {
    /* false = no blob */
    this.queue = new createjs.LoadQueue(false);
  }

  loadJSON(url: string, cache = true): Observable<any> {
    // PROBLEMATIC CODE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // if (this.jsonCache[url]) {
    //   return of(this.jsonCache[url]);
    // } else {
      return this.http.get<any>(url)
        .pipe(tap(data => {
          if (cache) this.jsonCache[url] = data;
          /* console.log('loadJSON result:', data); */
        }))
        .pipe(catchError(this.handleError));
    // }
  }

  loadHTML(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text', headers: { Accept: 'text/html' } })
      .pipe(catchError(this.handleError));
  }

  loadManifest(arr: Array<LoadItemData | string>, installSoundPlugIn: boolean = false): Observable<AssetData> {
    const returnObj = {};
    if (installSoundPlugIn && !this.soundInstalled) {
      this.queue.installPlugin(createjs.Sound);
      createjs.Sound.alternateExtensions = ['ogg'];
      this.soundInstalled = true;
    }
    this.queue.loadManifest(arr);
    return fromEvent(this.queue, 'complete')
      .pipe(
        map(data => {
        arr.forEach(val => {
          if (typeof val === 'string') {
            returnObj[val] = (data as LoadItemEvent).target.getResult(val);
          } else if (val.id || val.src) {
            returnObj[val.id || val.src] = (data as LoadItemEvent).target.getResult(val.id || val.src);
          }
        });
        this.queue.removeAllEventListeners();
        /* console.log('loadManifest results:', returnObj); */
        return returnObj;
      }))
      .pipe(catchError(this.handleError));
  }

  randomize(dta: any[]): any[] {
    return dta.map(obj => {
      obj.random = Math.random();
      return obj;
    }).sort((a, b) => a.random - b.random);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    const error: CustomError = {
      status: err.status,
      message: ErrorMessages.ASSETS
    };
    return throwError(error);
  }
}
