import * as fromRoot from '../../../state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SidebarState } from './sidebar.reducer';

export interface State extends fromRoot.State {
  sidebar: SidebarState;
}

const getSidebarState = createFeatureSelector<SidebarState>('sidebar');

export const getFlow = createSelector(
  getSidebarState,
  state => state.modules
);

export const getUpdate = createSelector(
  getSidebarState,
  state => state.modules
);

export const getError = createSelector(
  getSidebarState,
  state => state.error
);
