import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { AssetLoadService } from 'src/app/core/services/asset-load.service';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';


@Component({
  selector: 'app-static',
  template: `
    <div class="static-wrap" [ngStyle]="{'display': noButton ? 'block' : null}">
      <div #elements class="markup" [innerHTML]="markup | safeHtml"></div>
      <div #elements *ngIf="!noButton" class="btn-wrap">
        <app-button [text]="btnLabel" icon="next" [alt]="true" (click)="onContinue()"></app-button>
      </div>
      <div class="some-space" [ngClass]="{'no' : this.route.snapshot.data.json?.noSpace === true}">
        &nbsp;&nbsp;&nbsp;
      </div>
    </div>
  `,
  styleUrls: ['./static.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StaticComponent implements OnInit {

  @Input() noButton: boolean;
  markup: string;
  btnLabel = 'Continue';

  constructor(
    public route: ActivatedRoute,
    private userServ: UserService,
    private assetServ: AssetLoadService,
    private store: Store<fromShared.State>,
    private router: Router
  ) { }

  ngOnInit(): void {
    const htmlURL = this.route.snapshot.data.urlData.id
      ? `./assets/html/${this.route.snapshot.data.urlData.modID}/${this.route.snapshot.data.urlData.id}.html`
      : `./assets/html/${this.route.snapshot.data.helpID}/${this.route.snapshot.data.helpID}.html`;
      console.log(this.route.snapshot.data)
      console.log("htmlURL",htmlURL)
    this.assetServ.loadHTML(htmlURL)
      .subscribe(html => {
        this.markup = html;
        if (!this.noButton) this.store.dispatch( new loadAnimActions.Hide());
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
      });
  }

  onContinue(): void {
    this.userServ.activityComplete$.next(true);
    setTimeout(() => {
      this.router.navigateByUrl(this.route.snapshot.data.urlData.next);
    }, 300);
  }

}
