<div class="simple-modal" [ngClass]="modalClass" [@simpModalState]="state" (@simpModalState.done)="animEnd($event)" [@simpModalAnim]="state">

  <div class="simple-modal-txt">
    <h2 *ngIf="title">{{ title }}</h2>
    <p *ngIf="text">{{ text }}</p>
    <div *ngIf="html" [innerHTML]="html | safeHtml"></div>
  </div>

  <div class="btn-wrap" *ngIf="btnText">

    <app-button 
      *ngIf="isString(btnText); else twoBtns"
      [text]="btnText.toString()"
      [class]="isString(btnClass) ? btnClass.toString() : btnClass[0]"
      [icon]="btnIcon.toString()"
      [alt]="true"
      (click)="onBtnClick($event, hideDelay ? hideDelay : null)"
    >
    </app-button>

    <ng-template #twoBtns>
      <app-button
        *ngFor="let text of btnText; index as i"
        [class]="isString(btnClass) ? btnClass.toString() : (btnClass[i] ? btnClass[i] : btnClass[0])"
        [text]="text"
        [alt]="true"
        [icon]="isString(btnIcon) ? btnIcon.toString() : (btnIcon[i] ? btnIcon[i] : btnIcon[0])"
        (click)="onBtnClick($event, hideDelay ? hideDelay : null)"
      >
      </app-button>

    </ng-template>

  </div>
</div>
