import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-tab',
  template: `
    <div class="menu-tab {{menuTabState}}">
      <svg [ngClass]="'menu-' + menuTabState">
        <use [attr.xlink:href]="'./assets/svg_store/store.svg#menu-' + menuTabState"></use>
      </svg>
  </div>`,
  styleUrls: ['./menu-tab.component.scss']
})
export class MenuTabComponent {

  @Input() menuTabState: string;

  constructor() { }

}
