import { Injectable } from '@angular/core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { FlowService } from '../../services/flow.service';
import * as sidebarActions from './sidebar.actions';
import { ModMenuData } from '../../models';
import { UserService } from '../../services/user.service';

@Injectable()
export class SidebarEffects {

  constructor(
    private actions$: Actions,
    private flowService: FlowService,
    private userService: UserService
  ) { }

  loadFlow$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(sidebarActions.SidebarActionTypes.Load),
    mergeMap((action: sidebarActions.Load) => this.flowService.getData(this.userService.userType, this.userService.progress$.getValue()).pipe(
      map((modules: ModMenuData[]) => new sidebarActions.LoadSuccess(modules)),
      catchError(err => of(new sidebarActions.LoadFail(err)))
    ))
  ));

  updateFlow$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(sidebarActions.SidebarActionTypes.Update),
    mergeMap((action: sidebarActions.Update) => of(this.flowService.setStatus(action.payload, this.userService.progress$.getValue())).pipe(
      map((modules: ModMenuData[]) => new sidebarActions.UpdateSuccess(modules)),
      catchError(err => of(new sidebarActions.UpdateFail(err)))
    ))
  ));

}
