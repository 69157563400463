<div class="sidebar">
  <header>
    <div class="logo {{sidebarState}}"></div>
    <button 
      class="user-icon {{sidebarState}}" 
      (click)="onUserIcon()" 
      title="user-menu, logout"
    >
      <div class="user-icon-cont">
        <svg>
          <use [attr.xlink:href]="'./assets/svg_store/store.svg#user'"></use>
        </svg>
      </div>
    </button>
  </header>
  <nav>
    <app-mod-menu
      *ngFor="let mod of modules, let number = index"
      [data]="mod"
      [index]="number">
    </app-mod-menu>
  </nav>
</div>
