import { Directive, HostListener, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[kbResize]'
})
export class ResizeDirective implements AfterViewInit {

  private per: number;
  private max = .75;
  private w = 1280; // 1280 1536 1024
  /* private ratio = 960 / 1536; */ // 720/1280 672/1024 768/1024 960/1536

  @HostListener('window:resize', ['$event'])
  onResize(e: Event): void {
    this.per = this.el.nativeElement.clientWidth / this.w;
    this.per = this.per > this.max ? this.max : this.per;
    this.el.nativeElement.style.fontSize = (this.per * 100) + '%';
  }

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
    /*
      Firefox: sometimes maxWidth ends up being a really small number
      I assume the page is not yet rendered correctly to get proper clientWidth value
      run again in setTimeout
    */
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

}

