import { SidebarActions, SidebarActionTypes} from './sidebar.actions';
import { ModMenuData, CustomError } from '../../models';


export interface SidebarState {
  modules: ModMenuData[];
  next: string;
  error: CustomError;
}

const initState: SidebarState = {
  modules: undefined,
  next: '',
  error: undefined
};

export function reducer(state = initState, action: SidebarActions): SidebarState {

  switch (action.type) {

    case SidebarActionTypes.LoadSuccess:
      return {
        ...state,
        modules: action.payload,
        error: undefined
      };

    case SidebarActionTypes.LoadFail:
      return {
        ...state,
        error: action.payload
      };

    case SidebarActionTypes.Update:
      return {
        ...state,
        modules: action.payload,
        error: undefined
      };

    case SidebarActionTypes.UpdateSuccess:
      return {
        ...state,
        modules: action.payload,
        error: undefined
      };

    case SidebarActionTypes.UpdateFail:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
}
