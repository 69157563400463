import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';

import { UserService } from '../services/user.service';
import { CoreModule } from '../core.module';
import { MenuStatusTypes, ErrorMessages, HeaderTitles, OutsideUrls } from '../constants';
import { FlowService } from './flow.service';
import { environment } from 'src/environments/environment';
import { handleCustomError } from '../error-handler/handle-custom-error';
import { CustomError } from '../models';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private flowLoaded = false;
  private errorExists = false;

  constructor(
    private userService: UserService,
    private flowService: FlowService,
    private router: Router,
    private inj: Injector
  ) {
    this.userService.hasError$.subscribe(boo => this.errorExists = boo);
    this.flowService.hasError$.subscribe(boo => this.errorExists = boo);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.search('/error/') === -1) this.checkLoggedIn();
    return this.checkUrl(state.url);
  }

  private checkUrl(url: string): boolean {
    console.log(RouterStateSnapshot)
    console.log(this.errorExists);
    if(sessionStorage.token && sessionStorage.progress) {
      // if error exists return true to allow navigation to error / not-found page
      if (this.errorExists) {
        console.log('errorExists');
        return true;
      } 
      else if (!this.flowLoaded) {
        // first load: if no flowMap,  sub to flowMap and redirect to page that aligns with user progress
        const sub = this.flowService.flowMap$.subscribe(flow => {
          if (flow) {
            this.flowLoaded = true;
            const prog = this.userService.progress$.getValue();
            if (flow[prog.curModIndex]) {
              if (!environment.production) console.log(`AuthGuard: on first load nav to "${flow[prog.curModIndex].links[prog.linkIndexes].url}"`);
              console.log('flow[prog.curModIndex].links[prog.linkIndexes].url', flow[prog.curModIndex].links[prog.linkIndexes].url)
              this.router.navigateByUrl(flow[prog.curModIndex].links[prog.linkIndexes].url);
              setTimeout(() => {
                sub.unsubscribe();
              }, 100);
            } else {
              // redirect to profile if flow[prog.modIndex] is out of range. ie: course complete
              window.location.pathname = OutsideUrls.PROFILE;
            }
          }
        });
        return true;
      } else if (this.flowService.getPageStatus(url) === MenuStatusTypes.NOT_STARTED || this.flowService.getPageStatus(url) === MenuStatusTypes.LOCKED) {
        // if not in production allow all navigation. comment out below to check logic
        if (!environment.production) return true; else
          // if page is locked, return false
          return false;
      } 
      else {
        return true;
      }
    } else {
      this.userService.loggedIn$.next(false);
      this.router.navigateByUrl(`/`);
      return false;
    }
  }

  private checkLoggedIn(): void {
    // if not logged in, redirect to error page - force sign out
    console.log(this.userService.isLoggedIn(true))
    if (!this.userService.isLoggedIn(true)) {
      const error: CustomError = {
        status: HeaderTitles.SESSION_EXPIRED,
        message: ErrorMessages.SESSION_EXPIRED
      };
      handleCustomError(error, this.router, this.inj.get(Store), this.userService);

    }
  }
}
