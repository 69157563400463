import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import { QuizMessages } from 'src/app/core/constants';
import { QuizService } from './quiz.service';
import { QuizData, QuizReportData, QuizReportResponse } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizComponent implements OnInit, OnDestroy, OnChanges {

  form: FormGroup;
  questions: QuizData[];
  instructMsg: string;
  id: string;

  private scoreToPass = 0.8;
  private nextLink: string;
  private modID: string;
  private score: number;
  private response: any;
  private subs: Subscription[];
  private modalBtns = [
    {
      text: 'Review',
      icon: 'close',
      disabled: true,
      func: () => this.enableContinue()
    },
    {
      text: 'Continue',
      icon: 'next',
      disabled: true,
      func: () => this.onConinue()
    }
  ];
  private checkBoxAnswers = {};
  private matchingAnswers = {};
  private countToRestart = 3;
  private continueBtn = true;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromShared.State>,
    private service: QuizService,
    private formBuilder: FormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
   }

  ngOnInit(): void {
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.modID = this.route.snapshot.data.urlData.modID;
    this.id = this.route.snapshot.data.urlData.id;
    this.instructMsg = this.route.snapshot.data.json[this.id];
    //
    this.subs = [
      this.service.resultsResponse$.subscribe(dta => this.onResultsResponse(dta)),
      this.service.quizData$.subscribe(dta => this.buildQuiz(dta))
    ];
    //
    this.loadQuiz();
  }

  ngOnChanges() {
   
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  onSubmit(): void {
   // this.form.disable();
   console.log('onSubmit')
    this.service.reportResults(this.getResults());
    this.store.dispatch(new loadAnimActions.Show());
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.Title(Math.round(this.score * 100) + '%'));
    this.store.dispatch(new superModalActions.Buttons(this.modalBtns));
    this.store.dispatch(new superModalActions.Show());
  }

  onConinue(): void {
    if (this.response) {
      console.log('this.response', this.response)
      if (this.response.passed || this.id === 'pre') {
        this.service.continue(this.nextLink);
        this.service.markComplete();
      // } else if (this.response.restart) {
      //   this.service.restartModule();
      //   console.log("this.countToRestart", this.countToRestart)
      } else {
        //this.form.enable();
        this.countToRestart--;
        console.log("this.countToRestart", this.countToRestart)
          if (this.countToRestart === 0) {
            this.service.restartModule();
            console.log("this.countToRestart", this.countToRestart)
          } else {
            this.loadQuiz();
          }
        }
      }
  }

  enableContinue(): void {
    if(this.response) {
      if(this.response.passed) {
        this.continueBtn = false;
      }
    }
  }

  private loadQuiz(): void {
    this.response = undefined;
    this.score = undefined;
    this.checkBoxAnswers = {};
    this.service.loadQuizData(this.modID);
  }

  private buildQuiz(dta: QuizData[]): void {
    this.store.dispatch(new loadAnimActions.Hide());
    
    this.questions = dta;
    const questFGs = dta.map((q, index) => {
      const correctObj = q.choices.find(val => val.correct === 1)

      let correctIndex = [];
      if(q.type === 'radio' || q.type === 'checkbox') {
        q.choices.map((val, i) => {
          if(val.correct === 1) {
            correctIndex.push(i)
          }
        });
      } else if(q.type === 'matching') {
        q.choices.map((val, i) => {
            correctIndex.push(val.correct)
        });
      }
      return this.formBuilder.group({
        id: index,
        answer: [null, Validators.required],
        correctID: [correctIndex],
        type: q.type
      });
      //const correctIndex = q.choices.findIndex(val => val.correct === 1);
    });
    this.form = this.formBuilder.group({
      quiz: this.formBuilder.array(questFGs)
    });
  }

  private onResultsResponse(dta: QuizReportResponse): void {
    this.response = dta;
    // last try for the quiz before restart the module
    if(this.countToRestart === 1) {
      this.response.restart = true;
    }
    const msg = this.getMessage();
    //if (this.response.passed || this.id === 'pre') this.service.markComplete();
    setTimeout(() => {
      this.store.dispatch(new loadAnimActions.Hide());
      if (msg) this.store.dispatch(new superModalActions.HTML(msg));
      this.store.dispatch(new superModalActions.Buttons(this.modalBtns.map(obj => Object.assign({}, obj, { disabled: false }))));
    }, environment.production ? 0 : 100);
  }

  private getMessage(): string | void {
    if (this.id === 'pre') return;
    let msg: string;
    if (this.response.passed) {
      msg = QuizMessages.PASS;
    } else {
      msg = this.response.restart ? QuizMessages.RESTART : QuizMessages.FAIL;
    }
    return msg.replace('MOD_INDEX', this.service.modIndex.toString());
  }

  onRadioChange(event, i, j) {

    this.form.value.quiz.forEach((obj, index:number) => {
      if(obj.id == i) {

        obj.answer = JSON.stringify(j);
      }
    })

  }

  onCheckChange(event, i, j) {

    this.form.value.quiz.forEach((obj, index: number) => {

      if(obj.id == i && obj.answer == true && obj.type == "checkbox") {

        if(this.checkBoxAnswers[i] === undefined) {
          this.checkBoxAnswers[i] = []
          this.checkBoxAnswers[i].push(j)
        } else {
          this.checkBoxAnswers[i].push(j)
        }
        let finalString = '';
        this.checkBoxAnswers[i].map(num => {
          finalString += num;
        })
        obj.answer = finalString
      } 
      else if(obj.id == i && obj.answer == false && obj.type == "checkbox") {
        const index = this.checkBoxAnswers[i].indexOf(j, 0)
        if(index > -1) {
          this.checkBoxAnswers[i].splice(index, 1)
        }
        let finalString = '';
        this.checkBoxAnswers[i].map(num => {
          finalString += num;
        })
        obj.answer = finalString
      }
    })
    //console.log('this.form', this.form)
  }

  onSelectChange(option, i, j) {
    this.form.value.quiz.forEach((obj, index: number) => {
      if(obj.id == i && obj.type == "matching") {
        if(this.matchingAnswers[i] === undefined) {
          this.matchingAnswers[i] = {}
          this.matchingAnswers[i][j] = option;
          obj.answer = JSON.stringify(this.matchingAnswers[i])
        } else {
          this.matchingAnswers[i][j] = option;
          obj.answer = JSON.stringify(this.matchingAnswers[i])
        }
      }
      
    })
  }

  private getResults(): QuizReportData {
    let numCorrect = 0;
    this.form.value.quiz.forEach((obj, i: number) => {
      if(obj.type === 'radio') {
        obj.correctID.map(item => {
          if (obj.answer == item) {
            numCorrect++;
            this.questions[i].correctCode = 1;
          } else {
            this.questions[i].correctCode = 0;
          }
        })
      } else if(obj.type === 'checkbox') {
        const answerArr = obj.answer.split("");
        let checks = obj.correctID.length;
        for(let item of obj.correctID) {
          if(obj.correctID.length !== answerArr.length) {
            this.questions[i].correctCode = 0;
            break;
          } else {
            const index = answerArr.indexOf(JSON.stringify(item))
            if(index > -1) {
              checks--;
              if(checks == 0) {
                this.questions[i].correctCode = 1;
                numCorrect++;
              } else {
                this.questions[i].correctCode = 0;
              }
              
            } else {
              this.questions[i].correctCode = 0;
              break;
            }
          }
        }
      } else if(obj.type === 'matching') {
        const answer = JSON.parse(obj.answer);
        let checks = obj.correctID.length;

        obj.correctID.forEach((item, index) => {
          if(answer[index] === item) {
            checks--
            if(checks == 0) {
              this.questions[i].correctCode = 1;
              numCorrect++;
            } else {
              this.questions[i].correctCode = 0;
            }
          } else {
            this.questions[i].correctCode = 0;
          }
        })
      }
    });
    this.score = numCorrect / this.form.value.quiz.length;
    return {
      id: this.modID,
      passed: this.score >= this.scoreToPass,
      percent: this.score,
      postTest: this.id === 'pre' ? false : true
    };
  }

}
