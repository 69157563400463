import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import { UrlData } from 'src/app/core/models';
import { SwipeTypes, SwipeMoveData } from 'src/app/shared/directives/swipe.directive';
import { SwipeActJsonData, SwipeActItem } from './models';
import { gsap } from 'gsap';
import { UserService } from 'src/app/core/services/user.service';
import { BtnConfigData } from 'src/app/shared/models';

@Component({
  selector: 'app-swipe-act',
  templateUrl: './swipe-act.component.html',
  styleUrls: ['./swipe-act.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SwipeActComponent implements OnInit {

  @ViewChild('statement', { read: ElementRef }) statementEl!: ElementRef<HTMLElement>;
  data!: SwipeActJsonData;
  curItem!: SwipeActItem;
  swipeReady = false;

  private items!: SwipeActItem[];
  private urlData!: UrlData;
  private slideX!: number;
  private swipeDetected!: boolean;
  private width!: number;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromShared.State>,
    private userServ: UserService
  ) { }

  ngOnInit(): void {
    this.urlData = this.route.snapshot.data.urlData;
    this.data = this.route.snapshot.data.json;
    this.items = this.data.items.map(obj => {
      obj.random = Math.random();
      return obj;
    }).sort((a, b) => a.random - b.random);
    this.instruct();
  }

  onSwipe(dir: string): void {
    if (!this.swipeReady) return;
    this.swipeDetected = true;
    const answer = dir === SwipeTypes.Right ? this.data.right : this.data.left;
    const fb = answer === this.curItem.answer ? this.curItem.feedback.correct : this.curItem.feedback.incorrect;
    this.animate(dir, fb);
  }

  onSwipeMove(dta: SwipeMoveData): void {
    if (!this.swipeReady) return;
    this.slideX -= dta.difX * 0.35;
    this.width = this.statementEl.nativeElement.firstElementChild.clientWidth;
    gsap.set(this.statementEl.nativeElement.firstElementChild, {
      xPercent: this.slideX / this.width * 100,
      yPercent: Math.abs(this.slideX / this.width * 100) / 10,
      rotation: this.slideX / 45
    });
  }

  onTouchend(): void {
    if (!this.swipeDetected) {
      this.slideX = 0;
      gsap.to(this.statementEl.nativeElement.firstElementChild, {
        duration: 0.3,
        xPercent: 0,
        yPercent: 0,
        rotation: 0,
        ease: 'back.out'
      });
    }
  }

  onButton(clickData: string): void {
    const dir = clickData === this.data.right ? SwipeTypes.Right : SwipeTypes.Left;
    const fb = clickData === this.curItem.answer ? this.curItem.feedback.correct : this.curItem.feedback.incorrect;
    this.animate(dir, fb);
  }

  private animate(dir: string, fb: string): void {
    console.log("this.statementEl", this.statementEl)
    this.swipeReady = false;
    if (dir === SwipeTypes.Left) {
      gsap.to(this.statementEl.nativeElement.firstElementChild, {
        duration: 0.6,
        x: '-44em', yPercent: 45, rotation: -30, ease: 'power3.out',
        onComplete: () => this.feedback(fb)
      });
    } else if (dir === SwipeTypes.Right) {
      gsap.to(this.statementEl.nativeElement.firstElementChild, {
        duration: 0.6,
        x: '44em', yPercent: 45, rotation: 30, ease: 'power3.out',
        onComplete: () => this.feedback(fb)
      });
    }
  }

  private feedback(fb: string): void {
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.HTML(fb));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'Next',
      icon: 'next',
      class: 'blue',
      func: () => this.nextItem()
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

  private instruct(): void {
    const btnArr: BtnConfigData[] = [{
      text: 'Begin',
      icon: 'next',
      func: () => this.nextItem()
    }];
    if (this.urlData.complete) {
      btnArr.push({ text: 'Skip', icon: 'next', link: this.urlData.next });
    }
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.Title('Instructions'));
    this.store.dispatch(new superModalActions.HTML(this.data.instruct));
    this.store.dispatch(new superModalActions.Buttons(btnArr));
    this.store.dispatch(new superModalActions.Show(0.6));
  }

  private nextItem(): void {
    this.slideX = 0;
    this.swipeDetected = false;
    const item = this.items.pop();
    if (item) {
      this.curItem = null;
      setTimeout(() => {
        this.curItem = item;
      }, 300);
      setTimeout(() => {
        this.swipeReady = true;
      }, 800);
    } else {
      this.end()
    }
  }

  private end(): void {
    this.store.dispatch(new superModalActions.Reset());
    this.store.dispatch(new superModalActions.HTML(this.data.summary));
    this.store.dispatch(new superModalActions.Buttons([{
      text: 'Continue',
      icon: 'next',
      link: this.urlData.next,
      func: () => this.userServ.activityComplete$.next(true)
    }]));
    this.store.dispatch(new superModalActions.Show());
  }

}
