import { Injectable } from '@angular/core';

/**
 * Service wrapper for LocalStorage and SessionStorage
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Identifier appended to non-string values when stringified for storage.
   * This is so the value can be identified to be parsed to it's original type in the get method.
   */
  private indentifyAsJson = '::isJSON';

  /** Set to sessionStorage or localStorage in save method. */
  private storage: Storage;

  /** @ignore */
  constructor() { }

  /**
   * Save any item (value) to localStorage or sessionStorage
   * @param key name of item to be saved
   * @param value item to be saved
   * @param session save to sessionStorage if true; else save to localStorage
   */
  save(key: string, value: any, session: boolean = false): StorageService {
    this.storage = session ? sessionStorage : localStorage;
    if (typeof value !== 'string') value = JSON.stringify(value) + this.indentifyAsJson;
    if (value !== undefined || value !== null) this.storage[key] = value;
    return this;
  }

  /**
   * Get item from localStorage or sessionStorage as it's original type and value
   * @param key name of item to be retrieved
   */
  get(key: string): any {
    let val = localStorage[key] || sessionStorage[key];
    if (val && val.search(this.indentifyAsJson) !== -1) val = JSON.parse(val.replace(this.indentifyAsJson, ''));
    return val;
  }

/**
 * Delete item from localStorage and sessionStorage
 * @param key name of item to be deleted
 */
  remove(key: string): StorageService {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
    return this;
  }

  /**
   * Delete all items from localStorage, sessionStorage, or both.
   * @param local if true clear localStorage
   * @param session if true clear sessionStorage
   */
  clear(local: boolean = true, session: boolean = true): StorageService {
    if (local) localStorage.clear();
    if (session) sessionStorage.clear();
    return this;
  }
}
