import { Component, OnInit, ViewEncapsulation, Renderer2, ViewChild, ElementRef, OnDestroy, NgZone } from '@angular/core';
import { trigger, style, animate, transition, query, state, AnimationEvent } from '@angular/animations';
import { Observable, Subscription} from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromShared from '../state';
import * as superModalActions from './state/super-modal.actions';
import { BtnConfigData, ShowData, ApplyFuncData, InsertElementData } from '../models';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { btnDefault } from './state/super-modal.reducer';

@Component({
  selector: 'app-super-modal',
  templateUrl: './super-modal.component.html',
  styleUrls: ['./super-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('super-modalState', [
      state('hide', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      state('reset', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      state('show', style({
        opacity: 1,
        visibility: 'visible'
      })),
      transition('hide => show', animate('200ms linear')),
      transition('reset => show', animate('200ms linear')),
      transition('show => hide', animate('200ms 200ms linear'))
    ]),
    trigger('super-modalAnim', [
      transition('hide => show', [
        query('.super-modal', [
          style({ transform: 'scale(0.8, 0.8)' }),
          animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'scale(1, 1)' }))
        ])
      ]),
      transition('reset => show', [
        query('.super-modal', [
          style({ transform: 'scale(0.8, 0.8)' }),
          animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'scale(1, 1)' }))
        ])
      ]),
      transition('show => hide', [
        query('.super-modal', [
          style({ transform: 'scale(1, 1)' }),
          animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)', style({ transform: 'scale(0.8, 0.8)' }))
        ])
      ]),
    ])
  ]
})
export class SuperModalComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modalEl!: ElementRef;
  @ViewChild('textdiv') textDivEl!: ElementRef;
  @ViewChild('title') titleEl!: ElementRef;
  @ViewChild('text') textEl!: ElementRef;
  @ViewChild('html') htmlEl!: ElementRef;
  @ViewChild('btns') btnsEl!: ElementRef;

  animState = 'hide'; // 'show'
  show$!: Observable<ShowData>;
  title$!: Observable<string>;
  text$!: Observable<string>;
  buttons$!: Observable<BtnConfigData[]>;
  html$!: Observable<string>;
  insert$!: Observable<InsertElementData>;
  firstload$!: Observable<boolean>;
  insertedBefore = {
    title: false,
    text: false,
    html: false,
    btns: false
  };

  private onHideCompleteData!: ApplyFuncData | undefined;
  private onHideCompleteLink!: string | any[] | undefined;
  private insertedElements: { el: HTMLElement, parent: HTMLElement, oldParent: HTMLElement }[] = [];
  private subs: Subscription[] = [];
  private resetOnNav = false;

  constructor(
    private store: Store<fromShared.State>,
    private router: Router,
    private renderer: Renderer2,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.text$ = this.store.pipe(select(fromShared.getSuperModalText));
    this.title$ = this.store.pipe(select(fromShared.getSuperModalTitle));
    this.html$ = this.store.pipe(select(fromShared.getSuperModalHTML));
    this.buttons$ = this.store.pipe(
      select(fromShared.getSuperModalButtons),
      map(arr => arr.map(obj => Object.assign({}, btnDefault, obj)))
    );
    this.show$ = this.store.pipe(select(fromShared.getSuperModalShow));
    this.insert$ = this.store.pipe(select(fromShared.getSuperModalInsert));
    this.firstload$ = this.store.pipe(select(fromShared.getSuperModalFirstLoad));
    this.subs.push(
      this.show$.subscribe(dta => this.showHide(dta)),
      this.insert$.subscribe(dta => this.insertElement(dta)),
      /* this.firstload$.subscribe(dta => this.onFirstLoad(dta)), */
      this.router.events.subscribe(e => {
        if (e instanceof NavigationStart) {
          this.resetOnNav = true;
          this.store.dispatch(new superModalActions.Reset());
        } else if (e instanceof NavigationEnd) {
          this.resetOnNav = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new superModalActions.Reset());
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  animEnd(e: AnimationEvent): void {
    if (e.toState === 'hide') {
      if (this.onHideCompleteData) {
        this.onHideCompleteData.func.apply(this.onHideCompleteData.scope, this.onHideCompleteData.args);
        this.onHideCompleteData = undefined;
      }
      //
      if (this.onHideCompleteLink) {
        if (typeof this.onHideCompleteLink === 'string') {
          this.router.navigateByUrl(this.onHideCompleteLink);
        } else {
          this.router.navigate(this.onHideCompleteLink);
        }
        this.onHideCompleteLink = undefined;
      }
    }
  }

  onBtnClicked(btnConfig: BtnConfigData): void {
    if (btnConfig.func) {
      if (btnConfig.hide) {
        this.onHideCompleteData = {
          func: btnConfig.func,
          args: btnConfig.args,
          scope: btnConfig.scope || window
        };
      } else {
        btnConfig.func.apply(btnConfig.scope || window, btnConfig.args);
      }
    }
    //
    if (btnConfig.link) {
      if (btnConfig.hide) {
        this.onHideCompleteLink = btnConfig.link;
      } else {
        if (typeof btnConfig.link === 'string') {
          this.router.navigateByUrl(btnConfig.link);
        } else {
          this.router.navigate(btnConfig.link);
        }
      }
    }
    //
    if (btnConfig.hide) {
      this.store.dispatch(new superModalActions.Hide());
    }
  }

  private showHide(dta: ShowData): void {
    /*
      Sometimes, on first load, this function seems to be runnung outside of AngularZone.
      if ouside of zone animation fails to execute in Firefox, Edge, & Safari
      run in zone if NgZone.isInAngularZone() returns false
    */
    if (!NgZone.isInAngularZone()) {
      this.zone.run(this.showHide, this, [dta]);
      return;
    }
    //
    if (!dta.delay || dta.delay === 0) {
      this.animState = dta.show ? 'show' : (this.resetOnNav ? 'reset' : 'hide');
    } else {
      setTimeout(() => {
        this.animState = dta.show ? 'show' : 'hide';
      }, dta.delay * 1000);
    }
  }

  private insertElement(dta: InsertElementData): void {
    /* console.log('SuperModalComponent.insertElement', dta); */
    if (dta.remove) this.removeInsertElements();
    if (dta.element) {
      let beforeEL: HTMLElement;
      let parentEL: HTMLElement;
      if (!dta.before) dta.before = 'btns';
      this.insertedBefore[dta.before] = true;
      // timeout to allow elements to be created in template
      setTimeout(() => {
        switch (dta.before) {
          case 'title':
            beforeEL = this.titleEl.nativeElement;
            parentEL = this.textDivEl.nativeElement;
            break;

          case 'text':
            beforeEL = this.textEl.nativeElement;
            parentEL = this.textDivEl.nativeElement;
            break;

          case 'html':
            beforeEL = this.htmlEl.nativeElement;
            parentEL = this.textDivEl.nativeElement;
            if (beforeEL.innerHTML === 'null') beforeEL.innerHTML = '';
            break;

          default:
            /* btns */
            beforeEL = this.btnsEl.nativeElement;
            parentEL = this.modalEl.nativeElement;
            break;
        }
        this.insertedElements.push({ el: dta.element, parent: parentEL, oldParent: dta.element.parentElement as HTMLElement });
        this.renderer.insertBefore(parentEL, dta.element, beforeEL);
      }, 50);
    }
  }

  private removeInsertElements(): void {
    this.insertedElements.forEach(item => {
      this.renderer.removeChild(item.parent, item.el);
      if (item.oldParent
        && item.oldParent !== this.modalEl.nativeElement
        && item.oldParent !== this.textDivEl.nativeElement) this.renderer.appendChild(item.oldParent, item.el);
    });
    this.insertedElements = [];
    this.insertedBefore = {
      title: false,
      text: false,
      html: false,
      btns: false
    };
  }

  private onFirstLoad(boo: boolean): void {
    if (boo) {
      /*
        HACK: do inserts to prevent Firefox first load issue
        if on first load a component tries to insert a component's element into this modal,
        ngIf fails to show proper element in template
      */
      this.store.dispatch(new superModalActions.Insert({ element: document.createElement('span'), before: 'html' }));
      this.store.dispatch(new superModalActions.Insert({ element: document.createElement('span'), before: 'text' }));
      this.store.dispatch(new superModalActions.Insert({ element: document.createElement('span'), before: 'title' }));
    }
  }

}
