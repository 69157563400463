<div class="sk-outer-wrap">

  <app-static [noButton]=true></app-static>

  <div class="sk-wrap">

    <div class="slide-holder">
      <h2 class="title" [innerHTML]="curSlide['title'] | safeHtml"></h2>
      <p [innerHTML]="curSlide['text'] | safeHtml"></p>
      <img #slide [src]="curSlide.image" [class]="curSlide.size">
    </div>
  </div>
  <div class="choose-option-wrap" *ngIf="showOptionsToChoose && askToChooseOption">
    <button class="app-btn hover-reverse" (click)="clickToCheckAnswer(1)">Caregiver 1</button>
    <button class="app-btn hover-reverse" (click)="clickToCheckAnswer(2)">Caregiver 2</button>
  </div>
  <div class="bottom">
    <button class="arrow-btn" [disabled]="!activeBack" (click)="previousSlide()">
      <svg>
        <use xlink:href="./assets/svg_store/store.svg#back"></use>
      </svg>
    </button>
    <!-- <app-button icon="back" alt='true' [disabled]="!activeBack" (click)="previousSlide()"></app-button> -->
    <app-button [disabled]="!complete" text="Continue" icon="next" alt="true" [link]="nextLink" (click)="onContinue()"></app-button>
    <!-- <app-button icon="next" alt='true' [disabled]="!activeNext" (click)="nextSlide()"></app-button> -->
    <button class="arrow-btn" [disabled]="!activeNext" (click)="nextSlide()">
      <svg>
        <use xlink:href="./assets/svg_store/store.svg#next"></use>
      </svg>
    </button>
    
  </div>
  <div class="some-space">
    &nbsp;
  </div>
</div>