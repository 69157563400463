import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /* console.log(state.url, window.location.pathname, route.params.lang); */
    this.userService.isLoggedIn(true);
    return this.allowContent(state.url);
  }

  private allowContent(url: string): boolean {

      if(sessionStorage.token) {      
        this.userService.loggedIn$.next(true);
        return true;
      } else {
        this.userService.loggedIn$.next(false);
        console.log(this.userService.loggedIn$.value);
        this.router.navigateByUrl(`/`);
        return false
    }
  }
}
