import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { Subject } from 'rxjs';
import { QuizData, QuizReportData, QuizReportResponse } from 'src/app/core/models';
import { AssetLoadService } from 'src/app/core/services/asset-load.service';


@Injectable({
  providedIn: 'root'
})
export class QuizService {

  quizData$ = new Subject<QuizData[]>();
  resultsResponse$ = new Subject<QuizReportResponse>();
  modIndex: number;
  private curMod: string;

  constructor(
    private userServ: UserService,
    private assetServ: AssetLoadService,
    private router: Router
  ) { }

  loadQuizData(curMod: string) {
    this.curMod = curMod;
    const progressObj = JSON.parse(sessionStorage.progress)
    // this.modIndex = progressObj.modIndex;
    this.modIndex = this.checkCurrentModule(curMod);
    this.assetServ.loadJSON(`./assets/json/quiz/quiz-${curMod}.json`).subscribe(
      
      (dta: QuizData[]) => {
        console.log('service dta', dta)
        const quizData: QuizData[] = this.assetServ.randomize(dta);
        console.log('quizData', quizData)
        quizData.forEach((q: QuizData) => {
          q.choices = this.assetServ.randomize(q.choices);
          q.correctCode = -1;
        });
        console.log('quizData', quizData)
        this.quizData$.next(quizData)
      }
      
    );
  }

  checkCurrentModule(curMod: string) {
    if(curMod === "foundation-caregiving") {
      return 1;
    } else if (curMod === "precautions") {
      return 2;
    } else if (curMod === "what-to-expect") {
      return 3;
    } else if (curMod === "end-of-life") {
      return 4;
    } else if (curMod === "loss-grief") {
      return 5;
    } else if (curMod === "managing-stress") {
      return 6;
    } else if (curMod === "staff-training") {
      return 7;
    } else {
      return null;
    }
  }

  reportResults(results: QuizReportData) {
    results.id = this.curMod;
    console.log("results", results)
    // this.userServ.postQuizResults(results).subscribe(
    //   dta => {
    //     console.log("dta", dta)
    //     this.resultsResponse$.next(dta)
    //   }
    // );
    this.resultsResponse$.next({ passed: results.passed, restart: false })
  }

  restartModule() {
    const prog = this.userServ.progress$.getValue();
    prog.linkIndexes = 1;
    prog.curModIndex = this.modIndex;
    const url = this.userServ.flow[prog.curModIndex].links[prog.linkIndexes].url;
    this.userServ.progress$.next(prog);
    this.userServ.updateProgress(prog).subscribe(
      () => this.router.navigateByUrl(url)
    );
  }

  continue(url: string) {
    this.router.navigateByUrl(url);
  }

  markComplete() {
    this.userServ.activityComplete$.next(true);
  }
}
