<div class="sk-outer-wrap">

  <div class="text-wrap">
    <h2>{{data['title']}}</h2>
    <p>{{data['bodyText']}}</p>
  </div>
  <app-static [noButton]=true></app-static>
  <div class="sk-wrap">

    <div class="slide-holder">
      <div class="title" [innerHTML]="curSlide.title | safeHtml"></div>
      <img #slide [src]="curSlide.image">
      <div class="foot">
        <p>words</p>
      </div>
    </div>
    <div class="side-stripe-wrap">
      <div class="slide-text" [innerHTML]="curSlide.text | safeHtml"></div>
    </div>
  </div>


  <div class="bottom">
    <button class="arrow-btn" [disabled]="!activeBack" (click)="previousSlide()">
      <svg>
        <use xlink:href="./assets/svg_store/store.svg#back"></use>
      </svg>
    </button>
    <!-- <app-button icon="back" alt='true' [disabled]="!activeBack" (click)="previousSlide()"></app-button> -->
    <app-button [disabled]="!complete" text="Continue" icon="next" alt="true" [link]="nextLink" (click)="onContinue()"></app-button>
    <!-- <app-button icon="next" alt='true' [disabled]="!activeNext" (click)="nextSlide()"></app-button> -->
    <button class="arrow-btn" [disabled]="!activeNext" (click)="nextSlide()">
      <svg>
        <use xlink:href="./assets/svg_store/store.svg#next"></use>
      </svg>
    </button>
  </div>

  <div class="lastWords" *ngIf="lastWords && complete">{{lastWords}}</div>
  
  <div class="some-space">
    &nbsp;
  </div>
</div>