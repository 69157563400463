import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `
    <button [id]="id ? id : ''" class="app-btn {{ class }}" [attr.data-type]="alt ? 'alt' : ''" [type]="type ? type : ''" [disabled]="disabled" [routerLink]="routerLink" [queryParams]="params" queryParamsHandling="merge">
      <span class="icon-span">
        <div [ngClass]="iconNoBorder ? 'icon-holder-no-border' : 'icon-holder'">
          <svg [ngClass]="icon">
            <use [attr.xlink:href]="'./assets/svg_store/store.svg#' + icon"></use>
          </svg>
        </div>
      </span>
      <span class="text-span">{{ text }}</span>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent implements OnChanges {
  @Input() text: string;
  @Input() id: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() alt: boolean;
  @Input() link: string;
  @Input() type: string;
  @Input() iconNoBorder: boolean;

  params: any;
  routerLink: string;

  constructor() { }

  ngOnChanges(): void {
    if (this.link) {
      if (this.link.includes('?')) {
        const obj = {};
        const arr = this.link.split('?');
        this.routerLink = arr[0];
        const paramsArr = arr[1].split('&');
        paramsArr.forEach(str => {
          const keyVal = str.split('=');
          obj[keyVal[0]] = keyVal[1];
        });
        this.params = obj;
      } else {
        this.routerLink = this.link;
      }
    }
  }
}
