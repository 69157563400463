import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-video-timeline',
  template: `
    <div class="timeline-wrap">
      <div class="loadline" [ngStyle]="{'width.%': loadlineWidth}"></div>
      <div class="watched" [ngStyle]="{'width.%': watched}"></div>
      <div class="playline" [ngStyle]="{'width.%': playlineWidth}"></div>
    </div>`,
  styleUrls: ['./video-timeline.component.scss']
})
export class VideoTimelineComponent implements OnChanges {

  @Input() loadlineWidth: number;
  @Input() playlineWidth: number;
  @Input() duration: number;

  watched = 0;

  constructor() { }

  ngOnChanges(e) {
    if (e.duration) {
      this.watched = 0;
    }
    this.watched = this.playlineWidth > this.watched ? this.playlineWidth : this.watched;
  }

}
