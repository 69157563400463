import { SuperModalActions, SuperModalActionTypes } from './super-modal.actions';
import { ShowData, BtnConfigData, InsertElementData } from '../../models';

export interface SuperModalState {
  show: ShowData;
  title: string;
  text: string;
  html: string;
  buttons: BtnConfigData[];
  inserted: InsertElementData;
  firstload: boolean;
}

export const btnDefault: BtnConfigData = {
  text: 'OK',
  icon: 'check',
  id: undefined,
  class: 'fuchsia',
  data: undefined,
  func: undefined,
  args: [],
  scope: undefined,
  hide: true,
  link: undefined,
  disabled: false
};

const initState: SuperModalState = {
  show: { show: false, delay: null },
  title: null,
  text: null,
  html: null,
  buttons: [btnDefault],
  inserted: {element: null, remove: true, before: null},
  firstload: false
};

export function reducer(state = initState, action: SuperModalActions): SuperModalState {

  switch (action.type) {

    case SuperModalActionTypes.Show:
      return {
        ...state,
        show: { show: true, delay: action.payload }
      };

    case SuperModalActionTypes.Hide:
      return {
        ...state,
        show: { show: false, delay: action.payload }
      };

    case SuperModalActionTypes.Title:
      return {
        ...state,
        title: action.payload
      };

    case SuperModalActionTypes.Text:
      return {
        ...state,
        text: action.payload
      };
0
    case SuperModalActionTypes.HTML:
      return {
        ...state,
        html: action.payload
      };

    case SuperModalActionTypes.Buttons:
      return {
        ...state,
        buttons: action.payload
      };

    case SuperModalActionTypes.Insert:
      return {
        ...state,
        inserted: action.payload
      };

    case SuperModalActionTypes.FirstLoad:
      return {
        ...state,
        firstload: action.payload
      };

    case SuperModalActionTypes.Reset:
      return {...initState};

    default:
      return state;
  }
}
