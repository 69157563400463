<div class="video-shell-wrap" [ngClass]="{'has-txt': hasText}">

  <div class="spacer">
    <app-static *ngIf="hasText" [noButton]=true (click)="onClickVideo($event)"></app-static>
  </div>

  <div class="player-wrap">
    <app-video-player [source]="src" [stateSource]="stateSrc"
    [autoplay]="autoplay"
    (loaded)="onLoaded($event)"
    (played)="onPlayed($event)"
    (currentTime)="onCurrentTime($event)"
    (duration)="onDuration($event)"
    (complete)="onComplete()"
    (paused)="onPaused($event)"
    (waiting)="onWaiting($event)"
    (subtitles)="onSubtitles($event)">
  </app-video-player>

  <div class="video-controls-wrap">

    <app-video-timeline
      [loadlineWidth]="loadedPercent"
      [playlineWidth]="playedPercent"
      [duration]="duration">
    </app-video-timeline>

    <app-video-ui 
      [currentTime]="currentTime" 
      [duration]="duration" 
      [complete]="ended" 
      [next]="nextLink" 
      [isPaused]="paused" 
      [showSubtitles]="subtitles"
      (playToggle)="video.playToggle()"
      (subtitlesToggle)="video.subtitlesToggle()"
      (seek)="video.seek($event)">
    </app-video-ui>

  </div>
  </div>
  
</div>