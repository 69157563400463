<div class="slide-reveal-wrap">

  <div class="text-wrap">
    <h2 class="title-text">{{data['title']}}</h2>
    <p class="subtitle-text">{{data['subtitle']}}</p>
  </div>
  <div class="col-wrap">
    <mat-accordion expanded="false" multi="true">
      <div class="column" *ngFor="let panel of panels;let indexOfParent = index">
        
          <mat-expansion-panel class="title" (opened)="onClickPanel(indexOfParent, panel)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div [innerHTML]="panel.title"></div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="box-item" [innerHTML]="panel.description"></div>
          </mat-expansion-panel>
        
      </div>
    </mat-accordion>
  </div>

  

  <div class="btn-wrap">
    <app-button text="Continue" [disabled]="!complete" icon="next" alt="true" [link]="nextLink" (click)="onContinue()"></app-button>
  </div>

</div>
