<div class="click-reveal" [ngClass]="{'row-3': data.rowLength === 3 }">

  <app-static [noButton]=true></app-static>
  <div class="button" *ngFor="let button of buttons; let buttonIndex = index"
    [ngClass]="{'clicked': clicked[buttonIndex]}" (click)="onClick(buttonIndex)">
    <p>
      {{button.title}}
    </p>
  </div>
  <div class="btn-wrap">
    <app-button text="Continue" [disabled]="!complete" icon="next" alt="true" [link]="nextLink" (click)="onContinue()"></app-button>
  </div>

  <div class="some-space">
    &nbsp;
  </div>
</div>