import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetLoadService } from 'src/app/core/services/asset-load.service';
import { Store } from '@ngrx/store';
import * as fromShared from 'src/app/shared/state';
import { SSJsonData, SSSlideData } from './models';
import { UserService } from 'src/app/core/services/user.service';
import * as loadAnimActions from 'src/app/shared/loading-anim/state/loading-anim.actions';
import * as superModalActions from 'src/app/shared/super-modal/state/super-modal.actions';
import { gsap } from 'gsap';
import { HTML } from 'src/app/shared/super-modal/state/super-modal.actions';

@Component({
  selector: 'app-ss-sidekick',
  templateUrl: './ss-sidekick.component.html',
  styleUrls: ['./ss-sidekick.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SsSidekickComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private assetServ: AssetLoadService,
    private userServ: UserService,
    private store: Store<fromShared.State>
  ) { }

  @ViewChild('slide', { static: false }) private slide: ElementRef;
  @ViewChild('track', { static: false }) private track: ElementRef;

  private curIndex = -1;

  data!: SSJsonData;
  slides!: SSSlideData[];
  curSlide!: SSSlideData;
  allClicked = false;
  nextLink!: string;
  complete!: boolean;
  activeText!: HTML;
  activeBack: boolean;
  activeNext: boolean;
  lastWords!: string;

  ngOnInit(): void {
    console.log(this.route.snapshot.data.json);

    this.data = this.route.snapshot.data.json;
    console.log('this.data',this.data)
    this.slides = this.data.slides;
    console.log(this.slides);
    this.nextLink = this.route.snapshot.data.urlData.next;
    this.activeBack = false;
    this.activeNext = true;
    this.lastWords = this.data.lastWords;
    const manifest = [];
    this.curSlide = this.slides[0];

    this.slides.forEach((slide, i) => {
      manifest.push({ id: `ss-${this.route.snapshot.data.urlData.id}-${i + 1}`, src: slide.image });
    });
    this.assetServ.loadManifest(manifest).subscribe(() => this.onImagesLoaded())
    if(this.data.instruct) {
      this.instruct();
    }
    console.log(this.curSlide)
  }

  animate = (direction, index, adjust) => {
    if (direction === 'next') {
      gsap.to(this.slide.nativeElement, {
        duration: 0.5, xPercent: -105, ease: 'power3.out', onComplete: () => {
          this.curSlide = this.slides[this.curIndex];
          setTimeout(() => {
            gsap.fromTo(this.slide.nativeElement,
              { xPercent: 100 },
              { duration: 0.5, xPercent: 0, ease: 'power3.out' });
          }, 100)
        }
      });
    } else {
      gsap.to(this.slide.nativeElement, {
        duration: 0.5, xPercent: 105, ease: 'power3.out', onComplete: () => {
          this.curSlide = this.slides[this.curIndex];
          setTimeout(() => {
            gsap.fromTo(this.slide.nativeElement,
              { xPercent: -100 },
              { duration: 0.5, xPercent: 0, ease: 'power3.out' });
          }, 100)
        }
      });
    }
  }
  nextSlide(): void {
    if (this.activeNext) {
      this.allClicked = false;
      this.curIndex++;
      if (this.curIndex > 0) {
        if (this.curIndex === this.slides.length - 1) {
          this.complete = true;
          this.activeNext = false;
          this.animate('next', this.curIndex, 0)
        } else {
          this.activeBack = true;
          this.animate('next', this.curIndex, 0)
        }
      }
      else {
        this.curSlide = this.slides[this.curIndex];
        setTimeout(() => {
          gsap.to(this.slide.nativeElement, { duration: 0.5, x: 0, ease: 'power3.out' });
        }, 100);
      }
    }
    this.activeText = this.slides[this.curIndex].text
  }

  previousSlide(): void {
    if (this.curIndex === 1) {
      this.activeBack = false;
    }
    if (this.curIndex === this.slides.length - 1) {
      this.activeNext = true
    }
    if (this.curIndex > 0) {
      this.animate('back', this.curIndex, 0)
      this.curIndex--
    }
    this.activeText = this.slides[this.curIndex].text
  }

  onContinue(): void {
    this.userServ.activityComplete$.next(true);
  }

  private onImagesLoaded(): void {
    this.store.dispatch(new loadAnimActions.Hide());
    this.nextSlide();
  }

  private instruct(): void {
    gsap.set('.text-wrap.bottom h2', {transformPerspective: 1000});
    this.store.dispatch(new loadAnimActions.Hide());
    this.store.dispatch(new superModalActions.Title('Instructions'));
    this.store.dispatch(new superModalActions.HTML(`<p>${this.data.instruct}</p>`));
    this.store.dispatch(new superModalActions.Buttons([
      {
        text: 'Begin',
        icon: 'next'
      }
    ]));
    this.store.dispatch(new superModalActions.Show(0.6));
  }


}
