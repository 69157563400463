<div class="swipe-act-outer-wrap">

  <div class="text-wrap">
    <h2>{{data.title}}</h2>
    <p>{{data.text}}</p>
    <p [outerHTML]="data.instruct | safeHtml"></p>
  </div>

  <div class="swipe-act-wrap" kbSwipe (swipe)="onSwipe($event)" (swipeMove)="onSwipeMove($event)" (touchend)="onTouchend()">

    <div *ngIf="!curItem"></div>
    <app-simple-modal #statement *ngIf="curItem" [text]="curItem.statement" [showHide]="{show: true, delay: 0.5}"></app-simple-modal>
  
    <div class="btn-wrap">
      <app-button [text]="data.left" icon="thumbs-down" iconNoBorder="true" alt="true" [disabled]="!swipeReady" (click)="onButton(data.left)"></app-button>
      <app-button [text]="data.right" icon="thumbs-up" iconNoBorder="true" alt="true" [disabled]="!swipeReady" (click)="onButton(data.right)"></app-button>
    </div>
  </div>

</div>
