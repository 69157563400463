import { Component, OnChanges, Input, ViewEncapsulation, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition, state, AnimationEvent } from '@angular/animations';
import { ShowData } from '../models';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('simpModalState', [
      state('hide', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      state('show', style({
        opacity: 1,
        visibility: 'visible'
      })),
      transition('hide => show', animate('200ms linear')),
      transition('show => hide', animate('200ms 200ms linear')),
    ]),
    trigger('simpModalAnim', [
      transition('hide => show', [
        style({ transform: 'scale(0.8, 0.8)' }),
        animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({ transform: 'scale(1, 1)' }))
      ]),
      transition('show => hide', [
        style({ transform: 'scale(1, 1)' }),
        animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)', style({ transform: 'scale(0.8, 0.8)' }))
      ]),
    ])
  ]
})
export class SimpleModalComponent implements OnChanges {

  @Input() title: string;
  @Input() text: string;
  @Input() html: string;
  @Input() btnIcon: string | [string, string];
  @Input() btnText: string | [string, string];
  @Input() btnClass: string | [string, string] = 'orange';
  @Input() modalClass: string;
  @Input() hideDelay: number;
  @Input() showHide: boolean | ShowData;

  @Output() hideEvent = new EventEmitter<Event>();
  @Output() btnClick = new EventEmitter<Event>();

  state = 'hide';
  private lastBtnEvent: Event;

  constructor() { }

  ngOnChanges(e: SimpleChanges): void {
    if (e.showHide) {
      if (typeof this.showHide === 'boolean') {
        if (this.showHide) {
          this.show();
        } else {
          this.hide();
        }
      } else {
        if (this.showHide.show) {
          this.show(this.showHide.delay);
        } else {
          this.hide(this.showHide.delay);
        }
      }
    }
  }

  onBtnClick(e: Event, delay?: number): void {
    this.lastBtnEvent = e;
    this.hide(delay);
    this.btnClick.emit(e);
  }

  show(delay?: number): SimpleModalComponent {
    if (!delay) {
      this.state = 'show';
    } else {
      setTimeout(() => {
        this.state = 'show';
      }, delay * 1000);
    }
    return this;
  }

  hide(delay?: number): SimpleModalComponent {
    if (!delay) {
      this.state = 'hide';
    } else {
      setTimeout(() => {
        this.state = 'hide';
      }, delay * 1000);
    }
    return this;
  }

  animEnd(e: AnimationEvent): void {
    if (e.toState === 'hide') {
      this.hideEvent.emit(this.lastBtnEvent);
    }
  }

  isString(val: any): boolean {
    return typeof val === 'string';
  }
}
