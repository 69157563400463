<div class="slide-reveal-wrap">

  <div class="text-wrap">
    <h2>{{data['title']}}</h2>
    <p>{{data['bodyText']}}</p>
  </div>
  <div class="col-wrap">
    <div class="column" *ngFor="let col of columns;let indexOfParent = index">

      <div class="title">
        {{col.title}}
      </div>

        <div class="box-item {{data.boxClass}} column-{{indexOfParent}} {{data.boxHeight}} {{data.margin}} row-{{i}}"
          *ngFor="let row of col.rows; let i = index"
          (click)="indexOfParent === 0 ? onClick(i) : null">
          <span [innerHTML]="row.title"></span>
        </div>

    </div>
  </div>

  <div class="btn-wrap">
    <app-button text="Continue" [disabled]="!complete" icon="next" alt="true" [link]="nextLink" (click)="onContinue()"></app-button>
  </div>

</div>
