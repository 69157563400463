export enum MenuStatusTypes {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  LOCKED = 'LOCKED',
  NEXT_MODULE = "NEXT_MODULE"
}

export enum OutsideUrls {
  ABOUT = '/about.aspx',
  LOG_OUT = '/',
  PRIVACY = '/privacy.aspx',
  PROFILE = '/profile.aspx',
  TERMS = '/termsofuse_$STATE.aspx',
  USER_PROGRESS_TEMP = 'assets/json/flow/progress.json',
  FLOW = './assets/json/flow/flow-$STATE.json',
  HELP = './assets/images/help/',
  COURSE_COMPLETE = '/register/instructions_$STATE.aspx',
  RESOURCES = '/states/resources/resources_$STATE.aspx',
  HOME = '/ng/index.aspx'
}

export enum PageLabels {
  ABOUT = 'About',
  FAQ = 'FAQ',
  LOG_OUT = 'Log Out',
  PRIVACY = 'Privacy',
  PROFILE = 'Profile',
  TERMS = 'Terms of Use',
  RESOURCES = 'Resources',
  HOME = 'Home'
}

export enum HeaderTitles {
  HELP = 'Help',
  MALFORMED = 'DATA-MALFORMED',
  SESSION_EXPIRED = 'SESSION-EXPIRED',
}

export enum ErrorMessages {
  USER_PROGRESS = 'Unable to load or update the user\'s progress.',
  COURSE_FLOW = 'Unable to load the course.',
  TRY_AGAIN = 'Log out and try again.',
  ASSETS = 'The application could not load this activity.',
  SESSION_EXPIRED = 'Sorry, but the session has expired.',
  NO_HELP = 'Sorry, there is no help available for this page.',
  QUIZ = 'The results of the quiz can not be recorded.',
  LOGIN = 'We didn\'t recognize the username or password you entered, or there was a problem connecting to the server. Please try again.',
  SECURITY_QUESTION = 'Wrong answer!',
  UNAUTHORIZED = 'Your account has been locked! Please contact info@inmates-care.org to unlock your account.'
}

export enum Instructions {
  WHIZ_QUIZ = 'Click on one of the boxes to choose a question.',
  IG1 = 'PRESS on the arrows to navigate to the next screen.',
  IG1_TOUCH = '<svg width="50" height="50"><use xlink:href="./assets/svg_store/store.svg#swipe-icon"/></svg><p>SWIPE the screen<br>or<br>PRESS on the arrows to navigate to the next screen.</p>',
  IG2 = 'PRESS on the tabs below for more information.',
  ID_CHECK = '<p>Verify the ID based on today\'s date.</p><p>Press the "Examine" buttons to get a closer look at all the important information about this ID.</p>',
  GRID = 'Press arrow buttons for more information.',
  GRID_TOUCH = 'Press arrow buttons or swipe for more information.'
}

export enum QuizMessages {
  PASS = '<p class="bold green-txt">PASSED</p><p>Congratulations! You passed the required test for <span class="bold">Module MOD_INDEX</span> section of the <span class="italic bold">Just Care</span> training. </p><p>Press the <span class="bold">Continue</span> button to go to the Module Cetificate page.</p><p class="bold">Well done!</p>',
  FAIL = '<p class="bold red-txt">FAILED</p><p>Sorry, you did not pass. You must retake the quiz.</p><p>Press the <span class="bold">Continue</span> button to retake the quiz.</p>',
  RESTART = '<p class="bold red-txt">FAILED</p><p>Sorry, you did not pass. You must revisit the <span class="bold">Module MOD_INDEX</span> section of the training program and then retake the quiz.</p><p>Press the <span class="bold">Continue</span> button to revisit the section.</p>'
}

export const VIDEO_DOMAIN = 'https://video.kleinbuendel.com/P2P2-JustCare/';

export const SESSION_MINUTES = 60;
