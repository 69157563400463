import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation, ViewChild, ElementRef, OnInit } from '@angular/core';
import { gsap } from 'gsap/all';
import { SwipeTypes, SwipeMoveData } from 'src/app/shared/directives/swipe.directive';
import { HelpService } from './help-service/help.service';
import { ErrorMessages, HeaderTitles } from 'src/app/core/constants';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit, OnChanges {

  @Input() show: boolean;
  @Output() showChange = new EventEmitter<boolean>();
  @ViewChild('holder') holder: ElementRef;

  imageArr: HTMLImageElement[];
  fillArr: number[];
  noHelp = ErrorMessages.NO_HELP;
  helpTxt = HeaderTitles.HELP;
  closeTxt = 'Close';

  // tslint:disable-next-line: variable-name
  private _curIndex: number;
  private slideX: number;
  private swiping: boolean;

  get curIndex(): number {
    return this._curIndex;
  }

  set curIndex(num: number) {
    this._curIndex = num;
    if (this._curIndex !== undefined) this.goTo(this._curIndex);
  }

  constructor(private service: HelpService) { }

  ngOnInit(): void {
    this.service.images.subscribe( images => this.onNewImages(images) );
    this.service.autoClose.subscribe( () => this.close() );
  }

  ngOnChanges(e: SimpleChanges): void  {
    if (e.show.currentValue === true) {
      this.newImages();
      this.service.showing.next(true);
    } else if (e.show.currentValue === false) {
      setTimeout(() => {
        this.service.showing.next(false);
      }, 600);
    }
  }

  close(): void  {
    this.show = false;
    this.showChange.emit(this.show);
  }

  onSwipe(dir: string): void  {
    /* console.log(dir); */
    if (dir === SwipeTypes.Left && this.curIndex < this.imageArr.length - 1) {
      this.curIndex++;
      this.swiping = true;
    } else if (dir === SwipeTypes.Right && this.curIndex > 0) {
      this.curIndex--;
      this.swiping = true;
    }
  }

  onSwipeMove(dta: SwipeMoveData): void  {
    this.slideX -= dta.difX * 0.35;
    gsap.set(this.holder.nativeElement, { x: this.slideX });
  }

  onTouchEnd(): void  {
    this.slideX = 0;
    if (!this.swiping) gsap.to(this.holder.nativeElement, { duration: 0.3, x: this.slideX, ease: 'power3.in' });
  }

  private newImages(): void  {
    this.slideX = 0;
    this.swiping = false;
    this.imageArr = undefined;
    this.curIndex = undefined;
    this.service.loadImages();
  }

  private onNewImages(images: HTMLImageElement[]): void  {
    this.imageArr = images;
    this.curIndex = 0;
    this.fillArr = new Array(this.imageArr.length).fill(0);
  }

  private goTo(index: number): void  {
    gsap.to(this.holder.nativeElement, {
      duration: 0.5,
      xPercent: index * -(100 / this.imageArr.length),
      x: 0,
      ease: 'power2.out',
      onComplete: () => this.swiping = false
    });
  }

}
