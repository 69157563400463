/*
  routing within course will happen in CourseModule
  redirect to error page handled in AppComponent
  CourseModule redirects based on user progress handled in AuthGaurd
*/
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { environment } from '../environments/environment';

/* NgRx */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomReuseStrategy } from './custom-reuse-stategy';
import { ErrorHandlerService } from './core/error-handler/error-handler.service';
import { BlankComponent } from './core/blank/blank.component';
import { AuthGuard } from './core/services/auth-gaurd.service';
// import { LandingPageComponent } from './public/landing-page/landing-page.component';
import { PublicModule } from './public/public.module';
import { LoginGuard } from './core/services/login-guard.service';
import { UserComponent } from './public/user/user.component';

const routes: Routes = [
  {
    path: 'loading',
    component: BlankComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    data: { preload: false },
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'course',
    canActivate: [LoginGuard],
    data: { preload: true },
    loadChildren: () => import('./course/course.module').then(m => m.CourseModule)
  },
  { path: '', redirectTo: 'loading', pathMatch: 'full' } // default: will redirect based on user progress
];

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
    RouterModule.forRoot(routes),
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      name: 'P2P2 Devtools',
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ]
})
export class AppModule { }
